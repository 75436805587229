@use "src/styles/variables" as v;

.root {
  display: grid;
  align-items: center;
  align-content: center;
  height: 4.125em;
  background-color: var(--color-light-teal);
  padding: 0 2rem;

  grid-template-areas: "menu logo profile";
  grid-template-columns: min-content auto auto;
  column-gap: 2rem;
  @media (min-width: v.$hide-asides-width) {
    grid-template-areas: "logo profile";
    grid-template-columns: auto auto;
  }
}

.menuButton {
  display: block;
  background: none;
  border: none;
  grid-area: menu;
  cursor: pointer;
  @media (min-width: v.$hide-asides-width) {
    display: none;
  }
}

.menuIcon {
  padding: 0;
}

.logo {
  grid-area: logo;
  height: 25px;
  object-fit: contain;
}

.profile {
  grid-area: profile;
  place-self: end;
  position: relative;
}

.profileAvatar {
  cursor: pointer;
  border: solid 1.5px var(--color-accent);
  box-sizing: border-box;
}

.navOpen {
  z-index: 900;
}

.nav {
  position: absolute;
  top: 50px;
  right: 0;
}

.navCard {
  width: 213px;
  box-shadow: 0 24px 53px 0 rgba(0, 0, 0, 0.27);
}

.navList {
  list-style-type: none;
}

.navListItem {
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-background);
  }
}

.navListItemContainer {
  padding: 1rem;

  display: grid;
  align-content: center;
  align-items: center;
  column-gap: .75rem;
  grid-template-columns: min-content auto;
}

.navListItemLink {
  color: inherit;
  text-decoration: none;
  font-size: 1rem;
}

.navListItemButton {
  background: none;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  text-align: left;
  color: inherit;
}

.navListItemIcon {
  padding: 0;

  path {
    stroke: var(--color-grey-icon);
  }
}

.userWrap {
  display: flex;
  align-items: center;
}

.notificationLink {
  display: block;
  margin-right: 20px;
  margin-top: 3px;
  position: relative;

  svg {
    width: 24px;
    height: 24px;
  }
}

.rightPanelIconWrap {
  display: flex;
  align-items: center;
  padding: 4px;
  box-sizing: border-box;
  margin-right: 18px;
  cursor: pointer;
  border-radius: 50%;

  @media (min-width: v.$hide-asides-width) {
    display: none;
  }
}

.rightPanelActiveIconWrap {
  background-color: var(--color-white);
}

.bubble {
  $size: 6px;
  position: absolute;
  top: 18px;
  left: 17px;
  width: $size;
  height: $size;
  border-radius: $size;
  background-color: var(--color-danger);
}
