.setButton {
  color: var(--color-white);
}

.totalScore {
  display: flex;
  justify-content: flex-end;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: var(--color-secondary);

  p {
    color: var(--color-gray-title);
  }
}

.topInputGroup {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.goalInput {
  flex-basis: 70%;
}

.inputGroupsScore {
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  gap: 5px;
}

.criteriaContainer {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-left: 15px;
}

.criteriaColumn {
  display: flex;
  flex-basis: 70%;
  flex-direction: column;
  width: 400px;
  gap: 5px;
}

.inputDescription {
  text-align: start;
  flex-basis: 20%;
}

.inputDescription::first-letter {
  color: var(--color-danger);
}

.radioInput {
  display: flex;
  flex-direction: column;
  gap: 5px;

  label {
    display: flex;
    flex-direction: column-reverse;
    text-transform: capitalize;
  }
}

.inputDetails {
  flex-basis: 65%;
}

.inputScore {
  width: 50px;
}

.criteriaColumnScore {
  flex-basis: 20%;
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: 5px;
}

.inputGroup,
.motivationGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-wrap: nowrap;
  gap: 10px;
}

.inputGroup input,
.inputGroupsScore input {
  padding: 8px;
  border: 1px solid var(--color-light-gray);
  border-radius: 4px;
}

.motivationGroup {
  flex-basis: 20%;
  position: relative;
}

.scoreText {
  font-size: 12px;
  color: var(--color-green);
}

.motivationLabel {
  position: absolute;
  bottom: -15px;
  right: 30px;
  font-size: 12px;
}

.journalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
}

.link {
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}
