.questionsReadonly {
  div:last-child {
    margin-bottom: 0;
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  background-color: var(--color-gray);
  padding: 32px;
}

.button {
  font-weight: 500;
  width: 10rem;
  height: 2.7rem;
  margin-left: 20px;
}

.notProvided {
  font-style: italic;
}
