.footer {
  z-index: 1;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.body {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 40px;
  color: var(--color-secondary);
}

.flowerIcon {
  background-size: cover;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;

  img {
    width: 50px;
    height: auto;
    position: absolute;
    top: -10px;
    left: -50px;
  }
}

.header {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  color: var(--color-burgundy);

  h2 {
    margin: 0;
    color: var(--color-gray-title);
  }
}

.button {
  color: var(--color-white);
}
