.socialList {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.socialListCaption {
  font-size: 13px;
  font-weight: 600;
  line-height: 1.62;
  margin-bottom: 12px;
}

.socialListItem {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.socialListItem {
  font-size: 13px;
  color: var(--color-dark-gray);
  display: flex;
  align-items: center;
}

.socialListLink {
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
}
