.flagButton {
  height: 36px;
  padding: 11px 24px;
  box-sizing: border-box;
  border: 1px solid var(--color-lynx-white);
  background: var(--color-white);
  border-radius: 20px;
  cursor: pointer;

  svg {
    width: 13px;
    height: 12px;
  }
}

.flagTitle {
  display: inline-block;
  margin-left: 5px;
  font-size: 15px;
  line-height: 0.4;
  color: var(--color-black);
}

.flagAction {
  border-color: var(--color-black);
}
