.inputWrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.calendarIcon {
  position: absolute;
  $size: 1rem;
  height: $size;
  width: $size;
}

.calendarIconSmall {
  right: 0;
}

.calendarIconDefault {
  right: 1rem;
  top: 1rem;
}

.calendarIconWide {
  right: 1rem;
  top: 1.5rem;
}

.calendarIconLabResults {
  left: calc(100% - 0.6rem);
  top: 0.8rem;
}

.calendarIconMedications {
  right: 0;
  top: 0.8rem;
}

.input {
  margin: 0;

  &:focus {
    border: none;
    outline: solid 1px var(--color-black);
  }
}

.typeDefault {
  font-size: 1rem;
  width: calc(225px - 1rem);
  height: calc(3rem - 2px);
  border-radius: 8px;
  border: solid 1px var(--color-light-gray);
  padding-left: 1rem;
}

.typeSmall {
  width: 100%;
}

.typeWide {
  font-size: 1rem;
  width: calc(100% - 1rem);
  height: 60px;
  border-radius: 8px;
  border: solid 1px var(--color-light-gray);
  padding-left: 1rem;
}

.typeLabResults {
  font-size: 1rem;
  width: calc(100% - 2px);
  height: calc(3rem - 2px);
  border-radius: 8px;
  border: solid 1px var(--color-light-gray);
  padding-left: .88rem;
  padding-right: .62rem !important;
}

.typeMedications {
  font-size: 1rem;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: solid 1px var(--color-light-gray);
  padding-left: 12px;
}

.inputDisabled {
  background-color: var(--color-very-light-grey);
  color: var(--color-light-gray);
}

.inputWrapperLabResults {
  width: calc(100% - 1.5rem);
}

.inputWrapperMedications {
  width: calc(100% - 1rem);
}

.isValid {
  border-color: var(--color-danger);
}

.isValidBackground {
  background-color: var(--color-danger-light);
}

