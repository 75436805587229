@use "src/styles/mixins" as m;

.diseaseInfo {
  display: flex;
  justify-content: center;
  width: 100%;
}

.logo {
  background: url('../../../assets/logo/logo.png') no-repeat;
  background-size: contain;
  height: 25px;
  margin: 32px auto 97px;
}

.content {
  width: 444px;
  margin: 0 auto;
}

.mainTitle {
  font-size: 32px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 40px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.dropdown {
  margin-bottom: 16px;
  @include m.singleDropDownNormalize(60px);

  input {
    height: 60px !important;
  }
}

.dropdownError {
  @include m.singleDropDownNormalizeError(48px);
}

.contentImage {
  background: url('../../../assets/onboarding/disease-info/roadmap-reg.png') no-repeat;
  background-size: cover;
  background-position: 0 -40px;
  width: 100%;
  height: 235px;
  margin-bottom: 32px;
  border-radius: 12px;
}

.submitButton {
  font-size: 14px;
  font-weight: bold;
  width: 295px;
  height: 54px;
  margin: 0 auto;
  display: inherit;
}

.loadingButton div {
  top: -5px;
}

.additionalInfo {
  font-size: 13px;
  color: var(--color-dark-gray);
  margin-top: 110px;
  display: flex;
  justify-content: space-between;
}

.privacyLinks {
  display: flex;
  justify-content: space-between;
  width: 171px;
  margin-bottom: 32px;
}

.privacyLink {
  cursor: pointer;
  text-decoration: underline;
}

.errorMessage {
  display: flex;
  align-items: center;
  color: var(--color-danger);
  font-size: .75rem;
  margin-bottom: 0.5rem;

  span {
    display: inline-block;
    margin-left: 5px;
  }
}

.diseaseItemReadonly {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  border: solid 1px var(--color-light-gray);
  border-radius: 8px;
  padding: 8px 15px;
  height: 60px;
  box-sizing: border-box;

  .readonlyTitle {
    margin-bottom: 8px;
    font-size: 14px;
    color: var(--color-dark-gray);
  }
}
