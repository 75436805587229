@use 'sass:math';

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-burgundy);
  border: 0;
  padding: 0.6rem 2rem;
  color: var(--color-white);
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
  border-radius: 2rem;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: var(--color-burgundy-light);
  }

  &:active {
    filter: contrast(0.9);
  }

  &:disabled,
  &[disabled] {
    filter: opacity(0.5);

    &:hover {
      cursor: auto;
    }
  }
}

.block {
  display: block;
}

.flat {
  background: none;
  box-shadow: none;
  color: var(--color-black);
  padding: 0;

  &:hover {
    background: none;
  }
}

.slim {
  padding: 0.5rem 0.7rem;
  font-size: 0.8rem;
}

.big {
  padding: 1rem 3rem;
  font-size: 1.2rem;
}

.gray {
  background-color: var(--color-grey-link);
  box-shadow: none;
}

.lightGray {
  background-color: var(--color-light-gray);
  box-shadow: none;

  &:hover {
    background-color: var(--color-light-gray);
  }
}

.success {
  background-color: var(--color-success);
  box-shadow: none;
}

.primary {
  background-color: var(--color-primary-yellow);
  box-shadow: none;
  border-radius: 0;
}

.secondary {
  background-color: var(--color-accent);
  color: var(--color-black);
  &:hover {
    background-color: var(--color-accent-hover);
  }
}

.colorBlack {
  color: var(--color-black);
}

.radius {
  border-radius: 2rem;
}

.round {
  border-radius: 100%;

  .loadingWrapperLoad {
    border-radius: 100%;
  }
}

.bold {
  font-weight: bold;
}

.lowerCase {
  text-transform: none;
}

.loadingWrapperNormal {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: inherit;
  z-index: 0;
  opacity: 0;
  background-color: var(--color-burgundy);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.loadingWrapperLoad {
  opacity: 1;
  z-index: 901;
  cursor: default;
  border-radius: 2rem;
  background-color: var(--color-lynx-white);
}

.contentWrapper {
  z-index: 1;
  display: flex;
  align-items: center;

  svg {
    $size: 1.2rem;

    height: $size;
    width: $size;
  }
}

.addIcon {
  border-radius: 100%;
  background-color: var(--color-burgundy);
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  $size: 2.5rem;
  width: $size;
  height: $size;
  padding: 0;

  svg {
    padding: 0;
    width: $size * math.div(2, 3);
    height: $size * math.div(2, 3);
  }
}
