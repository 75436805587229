.file-uploader {
    display: flex;
    flex-direction: column;

    &--button {
        justify-content: flex-end;
        flex-direction: row;

        .file-uploader {
            &__button {
                margin-left: 25px;
                width: 150px;
            }
        }
    }

    &__label {
        padding: 0 0 7px 15px;
        line-height: 1.23;
        color: var(--color-dark-gray);
        font-size: 16px;
    }

    &__file-uploader {
        background-color: var(--color-white);
        height: 100px;
        border-radius: 10px;
        border: solid 2px var(--color-gray);
        box-sizing: border-box;
    }

    &__drop-container {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    &__drop-message {
        margin-top: 9px;
        font-size: 12px;
        color: var(--color-silver-sand);
    }

    &__cloud-download {
        height: 24px;
        width: 34px;
        margin: 0 auto 5px;

        svg {
            width: 100%;
            height: 100%;
        }

        &--auto {
            width: 175px;
            height: 123px;
        }
    }

    &__upload-link {
        text-decoration: underline;
        color: var(--color-white);
        padding-left: 4px;
        cursor: pointer;
    }

    &__file-input {
        display: none;
    }

    &__with-file {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
    }

    &__image-content {
        img {
            max-height: 50px;
        }
    }

    &__buttons-content {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        width: 150px;

        &-item {
            text-decoration: underline;
            color: var(--color-link);

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__error-content {
        flex-direction: column;
        font-size: 12px;
    }

    &__ok {
        text-decoration: underline;
        color: var(--color-link);
        margin-top: 10px;

        &:hover {
            cursor: pointer;
        }
    }

    &__dialog {
        position: absolute;
        top: calc(50% - 75px);
        left: calc(50% - 300px);
        right: 40px;
        border: 1px solid rgb(204, 204, 204);
        background: rgb(255, 255, 255);
        overflow: auto;
        border-radius: 20px;
        outline: none;
        padding: 20px;
        max-width: 600px;
        max-height: 250px;
    }
}
