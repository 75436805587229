.root {
  display: flex;
}

.image {
  width: 100%;

  &Wrapper {
    width: 30vw;
  }
}

.action {
  position: absolute;
  top: 0.625rem;
  width: 3rem;
  cursor: pointer;

  svg {
    width: 100%;
    height: auto;
  }

  &Download {
    right: 6rem;
  }

  &Close {
    right: 1rem;

    svg {
      height: 2rem;
    }
  }
}
