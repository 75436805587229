.root {
  display: grid;
  grid-row-gap: 0.5rem;
}

.rootMedicalHistory {
  width: 35rem;
}

.label {
  font-size: 13px;
  color: var(--color-dark-gray);
}

.selected {
  width: 442px;
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
}

.valueContainer {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0.5rem 1rem;
  border-radius: 21px;
  background-color: var(--color-very-light-grey);
  width: fit-content;
  margin-bottom: 0.25rem;
  margin-right: 0.5rem;
}

.value {
  font-size: 1rem;
}

.closeIcon {
  cursor: pointer;
  margin-left: 0.5rem;
}
