.line-chart {
  position: relative;

  &__line {
    fill: none;
    stroke: var(--color-accent);
    stroke-width: 1.5px;
  }

  &__value {
    fill: var(--color-accent);
    stroke: var(--color-white);
    stroke-width: 2px;
  }

  &__border-line {
    stroke-dasharray: 2 3;
    stroke-width: 1px;
    stroke: var(--color-success);
  }

  &__tick-line {
    stroke: var(--color-dark-gray);
    stroke-dasharray: 2 3;
    stroke-width: 1px;
  }

  &__axis-y {
    .tick {
      line {
        stroke-dasharray: 2 3;
        stroke-width: 1px;
        stroke: var(--color-background);
      }

      text {
        font-size: 13px;
        color: var(--color-dark-gray);
      }

      &:last-child {
        display: none;
      }
    }
  }

  &__label-y {
    fill: var(--color-dark-gray);
    font-size: 13px;
  }

  &__axis-x {
    path {
      stroke-width: 2px;
      stroke: var(--color-background);
    }

    text {
      font-size: 13px;
      color: var(--color-dark-gray);
    }

    .domain {
      stroke-dasharray: 2 3;
    }
  }
}
