.row {
  .field {
    font-size: 1rem;
    padding: 1rem 2rem;
    border-top: 1px solid var(--color-lynx-white);
    align-content: center;
    height: 30px;
    word-break: break-word;

    &:first-child {
      width: 50%;
    }

    &:last-child {
      width: 130px;
    }
  }

  &:hover {
    background: var(--color-very-light-grey);
  }
}


.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 121px;
  height: 31px;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 16px;
  border: solid 1px var(--color-silver);
  outline: none;
  cursor: pointer;
  background-color: var(--color-gray);
}
