.header {
  display: flex;
  justify-content: space-between;
}

.headerControl {
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
}

.title {
  display: block;
  font-weight: bold;
  line-height: 1.5;
  font-size: 32px;
  margin-bottom: 5px;
}

.date {

  display: block;
  font-size: 16px;
  color: var(--color-dark-gray);
  margin-bottom: 20px;
}

.printButton {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 8px;

    path {
      stroke: var(--color-white);
    }
  }
}

.reassessmentLink {
  text-decoration: none;
}

.reassessmentButton {
  margin-right: 15px;
}

.descriptionList {
  list-style: none;
  padding: 0;
  margin: 0 0 40px 0;
  display: flex;
}

.descriptionItem {

  font-size: 16px;
  width: 50%;
  margin-right: 25px;
}

.descriptionItem:last-child {
  margin-right: 0;
}

.assessmentDriversTitle {
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin: 40px 0 20px 0;
}

.assessmentDriversWrap {
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
}

.print {
  visibility: hidden;
  height: 0;
}
