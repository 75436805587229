.trellisVisualization {
  width: 100%;
  height: auto;
}

.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
