@use "src/styles/variables" as v;

.root {
  border-right: 1px solid var(--color-background);
}

.rootVisible {
  @media (max-width: v.$screen-responsive-width-low) {
    display: none;
  }
}

.header {
  background-color: var(--color-very-light-grey);

  @media (max-width: v.$screen-responsive-width-low) {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-very-light-grey);
  }
}

.headerControls {
  display: grid;
  grid-template-columns: 1fr;
  gap: .75rem;
}

.headerControlsExtended {
  grid-template-columns: auto 1fr;
}

.content {
  overflow-y: auto;
}

.contentCenter {
  display: grid;
  align-items: center;
  height: 100%;
}

.hoverable {
  &:hover {
    cursor: pointer;
    background-color: var(--color-background);
  }
}

.emptyListText {
  padding: 1.5rem 0;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}
