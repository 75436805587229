.root {
  display: grid;
  gap: .5rem;
}

.rootCondition {
  display: flex;
  flex-direction: column;
}

.error {
  border-color: var(--color-danger);
  background-color: var(--color-danger-light);
}

.errorText {
  display: grid;
  align-items: center;
  grid-template-columns: min-content auto;
  color: var(--color-danger);
  font-size: .75rem;
}

.questionWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.6rem
}

.conditionWrapper {
  display: flex;
  flex-direction: column;
}

.questionTitle {
  width: 100%;
  margin-right: 5px;
  font-weight: 500;
  font-size: 1rem;

  p {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.questionTitleCondition {
  font-size: 1rem;
  font-weight: 500;
}

.subElements {
  display: grid;
  gap: 0.2rem;
}

.cardContent {
  padding: 32px;
}

@media (max-width: 1440px) {
  .questionWrapper {
    gap: 1rem
  }
  .cardContent {
    padding: 24px;
  }
}

