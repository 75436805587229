.tableRow {
  cursor: pointer;

  .tableCell {
    font-size: 1rem;
    padding: 1rem 2rem;
    border-top: 1px solid var(--color-lynx-white);
    align-content: center;
  }
}
