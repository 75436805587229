.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.313rem;
}

.complete {
  display: flex;
  align-items: center;
  gap: 0.313rem;
}
.success {
  max-width: 15px;
}
