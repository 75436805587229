.root {
  padding: 1rem 2rem;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 740px) {
    padding: 0;
  }
}

.disclaimer {
  flex-grow: 1;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 8px;
  color: var(--color-grey-link);
  font-size: 13px;
  line-height: 1.7;
  background-color: var(--color-background-dark);
}

.text {
  color: var(--color-dark-gray);
  font-size: .8rem;
  margin-bottom: 15px;
}

.links {
  display: grid;
  justify-items: end;
  justify-content: end;
  align-items: center;
  grid-template-columns: auto auto;
  gap: 1rem;
}
