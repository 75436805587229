.optionWrapper {
  display: grid;
  gap: 1rem;
  grid-template-rows: min-content auto;

  &:hover {
    cursor: pointer;
  }
}

.optionWrapper:last-child {
  gap: normal;
  margin-bottom: 16px;
}

.optionWrapper:last-child.optionHaveError {
  margin-bottom: 8px;
}

.option {
  opacity: 0;
  position: absolute;
  pointer-events: none;

  & + *::before {
    $size: 18px;
    content: "";
    display: inline-block;
    vertical-align: bottom;
    box-sizing: border-box;
    width: $size;
    height: $size;
    border-radius: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: gray;
  }

  &:checked + *::before {
    background: radial-gradient(circle, var(--color-accent) 0%, var(--color-accent) 40%, transparent 45%, transparent);
    border-color: var(--color-accent);
  }
}

.optionError {
  & + *::before {
    border-color: var(--color-danger);
    background-color: var(--color-danger-light);
  }
}

.label {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: .3rem;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
}
