.numeric {
  width: 14.2rem;
  margin-bottom: 0.5rem;

  & > input {
    font-size: 1rem;
    border-radius: 8px;
    border: 1px solid var(--color-light-gray);
  }
}

.maskedInput {
  margin-bottom: 10px !important;
}
