.header {
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  border-bottom: 1px solid var(--color-lynx-white);
}

.label {
  font-size: 1.1rem;
  font-weight: bold;
}

.subLabel {
  font-size: 0.8rem;
  color: var(--color-dark-gray);
}
