.root {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.bottom {
  top: 96px;
  width: 100%;
}

.tooltip {
  width: 26rem;

  bottom: 100%;
  left: 100%;
  margin-left: -1rem;

  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 24px 53px 0 rgba(0, 0, 0, 0.27);
  background-color: var(--color-white);
}
