.root {
  margin-bottom: 20px;
}

.file {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;

  .fileInfo {
    display: flex;
    justify-content: space-between;
  }
}

.iconResult {
  margin-right: 12px;

  svg {
    width: 32px;
    height: 32px;
  }
}

.fileName {
  font-size: 1rem;
  word-break: break-all;
}

.fileDeleteBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-very-light-grey);
  margin-left: 8px;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 17px;
  }
}

.message {
  margin-top: 4px;
  display: block;
  color: var(--color-green);
  font-size: 13px;

  span {
    color: var(--color-danger);
    margin-bottom: 5px;
    display: block;
  }
}


