.root {
  font-size: 16px;
}

.parentCheckbox {
  display: flex;
  margin-bottom: 17px;


  .groupInput {
    display: none;

    & + *::before {
      $size: 18px;
      width: $size;
      height: $size;
      content: "";
      box-sizing: border-box;
      display: inline-block;
      vertical-align: bottom;
      border: 1px solid var(--color-dark-gray);
      border-radius: 2px;
    }

    &.minus + *::before {
      background-color: var(--color-accent);
      border-color: var(--color-accent);
      background-image: url("../../assets/icons/indeterminate.svg");
      background-repeat: no-repeat;
      background-position: center;
    }

    &.checked + *::before {
      background-color: var(--color-accent);
      border-color: var(--color-accent);
      background-image: url("../../assets/icons/ic_checkbox_white.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .groupLabel {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
    cursor: pointer;
    font-weight: 500;
    margin-right: 8px;
  }

  .visibleBtn {
    background: transparent;
    cursor: pointer;

    svg {
      position: relative;
      top: -1px;
    }

    &.visibleBtnOpen {
      transform: rotate(180deg);
    }
  }
}

.childrenCheckbox {
  margin-left: 28px;
  margin-bottom: 17px;

  label {
    align-items: normal;
    gap: 8px;
  }

  label::before {
    border: 1px solid var(--color-dark-gray);
  }
}


