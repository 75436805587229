@use 'src/styles/typography' as t;

.actions {
  position: absolute;
  right: 0.1rem;
  top: -10.3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cancel {
  font-weight: bold;
  font-size: 0.8rem;
  cursor: pointer;
  width: 80px;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 1.16px;
}

.button {
  height: 40px;
  font-weight: bold;
  align-items: center;
  font-size: 0.8rem;
  cursor: pointer;
  letter-spacing: 1.16px;
}

.cardGrid {
  position: relative;
  display: grid;
  grid-row-gap: 1.2rem;
}

.fields {
  display: grid;
}

.field {
  display: grid;
  grid-template-columns: 50% 50%;
  padding-top: 2rem;
  padding-bottom: 2rem;

  &.borderBottom {
    border-bottom: 1px solid var(--color-background);;
  }
}

.label {
  padding-left: 2rem;
  font-size: 1rem;
  font-weight: 500;

  &.horizontal {
    display: flex;
    justify-content: space-between;
  }
}

.subLabel {
  font-size: 13px;
  margin-top: 8px;
  color: var(--color-dark-gray);
  display: block;
  font-weight: normal;

  .yellow {
    color: var(--color-primary-yellow);
  }

  &.widthLimit {
    margin: 0 auto;
    max-width: 6rem;
    text-align: center;

  }

  .widthLimitWrapper {
    display: flex;
  }

}

.value {
  padding-right: 2rem;
  font-size: 1rem;
  overflow-wrap: anywhere;
  position: relative;

  & > div {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.radioContent {
  display: grid;
  grid-row-gap: 15px;
}

.radioSubLabel {
  margin-top: -8px;
  padding-left: 20px;

  &Span {
    @include t.RobotoMono();
    text-transform: uppercase;
    color: var(--color-black);
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.05px;
    padding: 0.3px 8px 0.5px;
    border-radius: 2px;

    &__red {
      background-color: var(--color-pink);
    }

    &__yellow {
      background-color: var(--color-accent);
    }
  }
}

.toggleLabel {
  margin-bottom: -2px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: var(--color-black);
}

.toggleSubLabel {
  margin-top: 8px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.05px;
  text-align: left;
  color: var(--color-dark-gray);
}

.toggleValue {
  margin-left: -2px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: var(--color-black);
}

.toggleControl {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.description {
  margin-top: 10px;
  font-size: 13px;
  color: var(--color-dark-gray);
}

.currentData {
  margin-top: 10px;
  font-size: 13px;
  color: var(--color-black);
}

.linkButton {
  color: var(--color-link);
  text-decoration: none;
  cursor: pointer;
}

.disclaimer {
  color: var(--color-danger);
}
