@use "../../../styles/typography" as t;

.content {
  border-radius: 20px;
  border: solid 2px var(--color-gray);
  min-height: 795px;
}

.labHeader {
  margin-bottom: 32px;
}

.labTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 680px;
}

.labTableHeader {
  display: grid;
  grid-template-columns: calc(40% - 121px) 18% 18% 18% minmax(121px, auto) 6%;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-gray);
  height: 44px;
  padding: 14px 32px;
  box-sizing: border-box;
  border-radius: 15px 15px 0 0;
  font-size: 13px;
  font-weight: 500;
  color: var(--color-dark-gray);
  text-transform: uppercase;
  border: 1px solid var(--color-lynx-white);
}

.labTableHeaderItem {
  text-align: left;
}

.labTableBody {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-left: 1px solid var(--color-lynx-white);
  border-right: 1px solid var(--color-lynx-white);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.labTableBodyItem {
  display: grid;
  grid-template-columns: calc(40% - 121px) 18% 18% 18% minmax(130px, auto) 6%;
  justify-content: space-between;
  align-items: center;
  min-height: 45px;
  border-bottom: 1px solid var(--color-lynx-white);
  padding: 9px 32px;
  box-sizing: border-box;

  &:hover {
    background: var(--color-very-light-grey);
    cursor: pointer;
  }

  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

.labTableBodyItem:nth-of-type(2n) {
  background: var(--color-very-light-grey);
}

.labTableBodyItemCell {
  overflow: hidden;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  word-break: break-word;
}

.labTableBodyItemCell:first-child {
  gap: 12px;
  margin-right: 20px;
}


.labTableBodyItemCell:nth-child(3) {
  word-break: break-all;
}

.labTableBodyItemCell:last-child {
  justify-content: flex-end;
}

.labTableButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 121px;
  height: 31px;
  padding: 6px 12px;
  border-radius: 4px;
  border: solid 1px var(--color-silver);
  outline: none;
  cursor: pointer;
  background-color: var(--color-gray);
  font-size: 16px;
  gap: 7px;
  word-break: normal;
}

.downloadIcon {
  display: inline-block;
}

.topContent {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 13px;
  background-color: var(--color-burgundy);
  color: var(--color-white);
  width: 200px;
  box-sizing: border-box;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    background-color: var(--color-burgundy-light);
  }
}

.noneResult {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-white-smoke);
  border-radius: 8px;
  padding: 32px 0;
}

.noneResultText {
  font-size: 1rem;
  color: var(--color-dark-gray);
  margin-bottom: 8px;
}

.btnUploadResult {
  font-size: 1rem;
  text-decoration: underline;
  background: none;
  cursor: pointer;
}

.mimeType, .iconType {
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mimeType {
  background: var(--color-white-smoke);
  border-radius: 20px;
}

.createdBy {
  &Name {
    font-size: 16px;
    color: var(--color-black);
  }

  &Type {
    font-size: 13px;
    letter-spacing: 0.05px;
    color: var(--color-dark-gray);
  }
}

.filename {
  @include t.text-overflow(16px, 1.31);
  white-space: normal;
}
