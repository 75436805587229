.container {
  width: 502px;
  margin: 0 20px 20px 0;
  padding: 24px;
  border-radius: 8px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &:nth-child(even) {
    margin-right: 0;
  }
}

.containerHeader {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  align-items: center;

  & > :last-child {
    margin-left: 10px;
  }
}

.containerHeaderTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
}

.containerDescription {
  margin-top: 18px;
  font-size: 16px;
  line-height: 1.31;
}

.progressBar {
  margin-top: 16px;
}

.barLineSubtitle {
  margin-top: 8px;
  font-size: 13px;
  font-weight: normal;
  color: var(--color-dark-gray);
  display: flex;
  justify-content: space-between;
}

.print {
  width: auto;
  background: transparent;
  border: 1px solid var(--color-lynx-white);
  padding: 18px;

  .containerHeaderTitle {
    line-height: 1;
  }

  .containerDescription {
    font-size: 14px;
  }
}
