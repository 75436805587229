.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;


  .button {
    border-radius: 0.5rem;
  }
}


.subHeader {
  font-size: 13px;
  letter-spacing: 0.05px;
  color: var(--color-dark-gray);
  margin-bottom: 2rem;
}
