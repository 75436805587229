.root {
  display: flex;

  .options {
    margin-left: 18px;

    .radioButtonGroup div {
      grid-gap: 1.05rem;
    }

    .readonly {
      display: flex;
      font-size: 16px;
      grid-gap: 1rem;
      flex-direction: column;

      .radio {
        content: "";
        display: inline-block;
        vertical-align: bottom;
        box-sizing: border-box;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        border: 1px solid var(--color-light-gray);
        margin-right: 8px;
        background: var(--color-very-light-grey);

        &.active:before {
          content: "";
          display: flex;
          width: 18px;
          height: 18px;
          background: radial-gradient(circle, var(--color-light-gray) 0%, var(--color-light-gray) 40%, transparent 45%, transparent);
          position: relative;
          top: -1px;
          left: -1px;
        }
      }
    }
  }
}
