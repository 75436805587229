.wrapperRecipient {
  max-width: 50%;
  background-color: var(--color-background);
  margin-right: auto;
  margin-bottom: 0.25rem;
  padding: 0.5rem;
  min-width: 50px;
}

.wrapper {
  max-width: 50%;
  background-color: var(--color-accent);
  margin-left: auto;
  margin-bottom: 0.25rem;
  padding: 0.5rem;
  min-width: 50px;
}

.date {
  grid-area: date;
  text-align: left;
  font-size: .7rem;
  margin-bottom: 0.5rem;
}

.content {
  align-items: center;
}

.contentRecipient {
  align-items: center;
}

.imgLink {
  display: block;
  cursor: pointer;
}

.image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.file {
  display: flex;
}
.fileIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 38px;
  height: 38px;
  background-color: var(--color-white);
  margin-right: 12px;
}

.fileName {
  margin-bottom: 5px;
}

.fileInfo {
  display: flex;
}

.fileSize {
  margin-right: 2px;
}

.fileLink {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 2px;
}
