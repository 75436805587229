.root {
  border-bottom: 1px solid var(--color-lynx-white);

  &:last-child {
    border: none;
  }
}

.notProvided {
  font-style: italic;
  font-size: 1rem;
}
