.title {
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.75rem;
  padding-left: 0.8rem;

  display: flex;
  gap: 0.8rem;

  svg {
    cursor: pointer;
    width: 1rem;
  }

  p {
    font-size: 0.875rem;
    font-weight: normal;
    text-decoration: underline;
    color: var(--color-grey-link);
    cursor: pointer;
  }
}

.items {
  display: grid;
  grid-row-gap: 0.5rem;
  margin-bottom: 1.5rem;
}


.noNotificationsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.noNotificationsText {
  font-weight: 500;
  font-size: 1rem;
  padding: 2.1rem 0;
  color: var(--color-dark-gray);
}
