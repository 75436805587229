.root {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;

  & > * {
    &:not(:first-child) {
      margin-left: 8px;
    }
  }

  &.bigIndent > * {
    &:not(:first-child) {
      margin-left: 16px;
    }
  }
}

.borderTop {
  border-top: 1px solid var(--color-background);
}
