.date-input {
  &__container {
    position: relative;
  }

  &__field {
    height: 30px;
    border-radius: 30px;
    border: solid 1px var(--color-light-gray);
    outline: none;
    padding: 0 25px 0 15px;
    background-color: var(--color-white);
    box-sizing: border-box;

    &:focus {
      border-color: var(--color-black);

      & + .date-input__icon {
        path {
          fill: var(--color-accent);
        }
      }
    }

    &::-ms-clear {
      //for IE, EDGE
      display: none;
    }

    &--invalid {
      border-color: var(--color-danger);

      &:focus {
        border-color: var(--color-danger);
      }
    }

    &--disabled {
      background-color: var(--color-dark-gray);
    }
  }

  &__icon {
    position: absolute;
    top: 7px;
    right: 10px;
    cursor: pointer;
  }
}
