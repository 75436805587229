.card {
  width: 676px;
  max-width: 80vw;
}

.title {
  padding: 13px 16px;
  border-radius: 8px;
  background-color: var(--color-gray);
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    cursor: pointer;
    font-size: 16px;
    line-height: 1.31;
  }
}

.headerRootClassName {
  padding: 24px 24px 0;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.contentRootClassName {
  padding: 24px;
}

.details {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  grid-gap: 24px 0;
}

.detailLabel {
  font-size: 13px;
  color: var(--color-dark-gray);
}

.detailValue {
  margin-top: 8px;
  font-size: 16px;
  color: var(--color-black);
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.labTestsIncluded {
  margin-top: 32px;
}

.labTestsIncludedTitle {
  font-size: 18px;
  font-weight: 500;
  color: var(--color-black);
}

.labTestsIncludedItem {
  margin-top: 24px;
  font-size: 16px;
  color: var(--color-black);

  .labTestsIncludedSubtitle {
    font-weight: 500;
  }

  .labTestsIncludedSubItem {
    margin-top: 16px;
  }
}

.note {
  margin-top: 24px;
}

.noteTitle {
  font-size: 13px;
  color: var(--color-dark-gray);
}

.noteValue {
  margin-top: 8px;
  font-size: 16px;
  color: var(--color-black);
  line-height: 1.63;
  white-space: pre-line;
  word-break: break-all;
}
