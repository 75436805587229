.leaf {
  stroke-width: 0px;
}

.red {
  fill: #9e1f58;
}

.yellow {
  fill: #fbbc31;
}
