.fields {
  display: grid;
}

.fieldsTitle {
  font-size: 18px;
  font-weight: bold;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  border-bottom: 1px solid var(--color-lynx-white);
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.submit {
  width: 11.25rem;
  height: 3rem;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 1.25px;
  padding: 0;
}
