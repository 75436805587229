.rootBorder {
  border-bottom: 1px solid var(--color-background);
}

.root {
  padding: 1rem 1rem 0;
  box-sizing: border-box;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subHeaderCenter {
  justify-content: center;
}

.metaText {
  color: var(--color-secondary);
  font-size: .8rem;
}

.title {
  font-weight: bold;
  margin-top: .5rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  max-width: 80%;
  display: flex;
}

.titleCenter {
  max-width: 100%;
  text-align: center;
  padding-top: 40px;
}

.titleLarge {
  font-size: 1.5em;
}

@media (max-width: 480px) {
  .titleLarge {
    font-size: 1.2em;
  }
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  color: black;
  font-size: 1rem;
  z-index: 1000;


  &:hover {
    cursor: pointer;
  }
}

.logo {
  height: 25px;
  margin-bottom: 26px;
}
