.stepContent {
  position: relative;
}

.closeButton {
  position: absolute;
  right: 20px;
  top: 20px;
  background: inherit;
  cursor: pointer;

  svg {
    $size: 15px;
    width: $size;
    height: $size;
  }
}
