.gridRow {
  display: grid;
  grid-template-columns: 20% 30% 20% 15% 15%;
  font-size: 16px;
  font-weight: normal;
  border: solid 1px var(--color-lynx-white);
  border-top: none;
  padding: 20px 0 20px 32px;
  color: var(--color-black);
  background-color: var(--white) !important;


  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.gridRowItem {
  cursor: default;
  pointer-events: none;
  opacity: 0.5;
}

.gridAdditionalInfo {
  font-size: 14px;
  font-weight: normal;
  margin-top: 4px;
  margin-right: 10px;
}

.viewSummary {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
