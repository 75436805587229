.root {
  display: flex;

  .options {
    display: grid;
    gap: 1rem;

    .readonly {
      display: flex;
      font-size: 16px;
      grid-gap: 1rem;
      flex-direction: column;
    }
  }
}




