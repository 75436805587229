.actions {
  position: absolute;
  right: 0;
  top: -9rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.personalInfoActions {
  top: -17rem;
}

.cancel {
  font-weight: bold;
  font-size: 0.8rem;
  cursor: pointer;
  width: 80px;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 1.16px;
}

.button {
  height: 40px;
  font-weight: bold;
  align-items: center;
  font-size: 0.8rem;
  cursor: pointer;
  letter-spacing: 1.16px;
}

.fields {
  display: grid;
}

.field {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 2rem;
  padding-bottom: 2rem;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-lynx-white);
  }
}

.label {
  padding-left: 2rem;
  font-size: 1rem;
  font-weight: 500;
  &.horizontal {
    display: flex;
    justify-content: space-between;
  }
}

.value {
  padding-right: 2rem;
  font-size: 1rem;
  overflow-wrap: anywhere;
}

.someValues {
  display: flex;
  gap: 1rem;

  label {
    width: auto;
  }
}

.subValue {
  margin-top: 16px;
  padding: 20px;
  background: var(--color-grey-secondary);

  &Label {
    margin-bottom: 16px;
    font-weight: 500;
  }
}

.radioBtn {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.italicText {
  font-style: italic;
}

.cardGrid {
  position: relative;
  display: grid;
  grid-row-gap: 1.2rem;

  :global(.react-datepicker-wrapper) {
    width: 100%;
  }

  :global(.react-datepicker__input-container div) {
    width: 100%;
  }

  :global(.react-datepicker__input-container input) {
    height: 48px;
  }

  :global(.react-datepicker__input-container svg) {
    top: 1rem;
  }

  :global(.single-dropdown input) {
    height: 48px;
    font-size: 1rem;
  }

  :global(.single-dropdown input:focus) {
    height: 48px;
  }

  :global(.single-dropdown__option-text) {
    font-size: 1rem;
  }
}

.avatarContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;
}

.avatar {
  width: 74px;
  height: 74px;
  font-size: 20px;
  font-weight: 500;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4.6rem;
  margin-right: 24px;
  border: solid 1.5px var(--color-accent);
  box-sizing: border-box;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  object-fit: cover;
}

.green {
  background-color: var(--color-success);
  color: white;
}

.red {
  background-color: var(--color-danger);
  color: white;
}

.gray {
  background-color: var(--color-dark-gray);
  color: white;
}

.yellow {
  background-color: var(--color-accent);
  color: black;
}

.name {
  font-size: 1.2rem;
  font-weight: 500;
}

.info {
  font-size: 1rem;
  color: var(--color-dark-gray);
}

.uploadButton {
  & > div:nth-child(3) {
    width: 10.7rem;
    height: 2.5rem;
    margin-right: 0.5rem;
    padding: 0;
    background-color: var(--color-burgundy);
    border: 0;

    &:hover {
      background-color: var(--color-burgundy-light);
    }

    & > div {
      font-size: 0.8rem;
      font-weight: bold;
      letter-spacing: 1.16px;
      text-transform: uppercase;
      color: var(--color-white);
    }
  }
}

.deleteButton {
  width: 7.3rem;
  height: 2.5rem;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 1.16px;
  padding: 0;
  background-color: var(--color-white);
  border: solid 1px var(--color-black);
  color: var(--color-black);

  &:hover {
    background-color: var(--color-very-light-grey);
  }
}

.dateSelector svg {
  display: none;
}

.dateSelector input {
  height: 40px !important;
}

.dateSelector label {
  padding: 0 !important;
}

.inputField {
  input {
    height: 48px !important;
  }
}

.otherLanguage, .errorField {
  margin-top: 8px;
}

.description {
  margin-top: 10px;
  font-size: 13px;
  color: var(--color-dark-gray);

  .link {
    color: unset;
  }
}

.emailChangeInfo {
  font-weight: bold;
  font-size: 14px;
  color: var(--color-danger);
  margin-top: 10px;
}

.inputZipCode {
  input {
    text-transform: uppercase;
  }

  ::-webkit-input-placeholder {
    text-transform: none;
  }

  ::-moz-placeholder {
    text-transform: none;
  }

  :-ms-input-placeholder {
    text-transform: none;
  }

  ::placeholder {
    text-transform: none;
  }
}

.subLabel {
  font-size: 13px;
  margin-top: 8px;
  color: var(--color-dark-gray);
  display: block;
  font-weight: normal;

  .yellowMark {
    color: var(--color-primary-yellow);
  }

  &.widthLimit {
    margin: 0 auto;
    max-width: 6rem;
    text-align: center;

  }

  .widthLimitWrapper {
    display: flex;
  }

}
