.root {
  background-color: var(--color-very-light-grey);
  padding: 0 392px 0 324px;
  @media (max-width: 1299px) {
    padding: unset;
  }

  &.center {
    padding: 0 358px;

    @media (max-width: 1299px) {
      padding: unset;
    }
  }
}

.disclaimer {
  padding: 15px;
  box-sizing: border-box;
  border-radius: 8px;
  color: var(--color-grey-link);
  font-size: 13px;
  line-height: 1.7;
  background-color: var(--color-background-dark);

  @media (max-width: 1299px) {
    margin: unset;
  }
}

.wrap {
  display: flex;
  padding: 16px 2em;
  justify-content: space-between;

  @media (min-width: 1300px) {
    display: flex;
    gap: 5px;
    max-width: 1024px;
    margin: 0 auto;
  }
}

.light {
  background-color: var(--color-white);
}
