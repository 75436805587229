@mixin scrollbar() {
  $color: var(--color-grey-scroll);
  $background-color: var(--color-white);

  $margin: 0;
  $margin2: 0;

  scrollbar-width: thin;
  scrollbar-color: $color $background-color;

  &::-webkit-scrollbar {
    width: 12px + $margin * 2;
    height: 12px + $margin * 2;
  }

  &::-webkit-scrollbar-button {

    &:horizontal:start {
      width: 8px + $margin2 * 2;
      height: 8px + $margin * 2;
    }

    &:vertical:start {
      width: 8px + $margin * 2;
      height: 8px + $margin2 * 2;
    }

    &:horizontal:end {
      width: 8px + $margin2 * 2;
      height: 8px + $margin * 2;

      &:corner-present {
        width: 0;
        height: 0;
      }
    }

    &:vertical:end {
      width: 8px + $margin * 2;
      height: 8px + $margin2 * 2;

      &:corner-present {
        width: 0;
        height: 0;
      }
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 30px + $margin * 2;
    border: 4px solid $background-color;
    background-color: $color;

    &:hover {
      background: var(--color-grey-scroll-bg-hover);
    }
  }

  &::-webkit-scrollbar-corner {
    width: 12px + $margin * 2;
    height: 12px + $margin * 2;
  }
}
