.backToTop {
  position: fixed;
  padding: 9px 40px;
  gap: 10px;
  bottom: 15px;
  right: 15px;
  font-size: 18px;
  color: var(--color-black);
  background: var(--color-accent);
  text-align: right;
  text-transform: uppercase;
  border: 2px solid var(--color-accent);
  border-radius: 35px;
  cursor: pointer;
  &:hover {
    border-color: var(--color-black);
  }
}

