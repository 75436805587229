.root {
  display: grid;
  grid-row-gap: 2rem;
  max-width: 27.75rem;
  margin: 0 auto;
}

.title {
  font-size: 2rem;
  font-weight: bold;
}

.form {
  display: grid;
  grid-row-gap: .75rem;
  grid-template-columns: 27.75rem;
}

.datepicker {
  width: auto;
  height: 60px;

  * {
    border-radius: 10px;
    border-color: var(--color-background);
    border-width: .15rem;
    height: 100%;
  }
}

.input {
  input {
    margin-bottom: 0 !important;
    border: solid 1px var(--color-light-gray) !important;
    font-weight: 500;

    &::placeholder {
      font-weight: normal;
    }
  }
}

.checkboxContainer {
  display: grid;
  gap: .5rem;
  grid-template-columns: min-content auto;
  align-items: center;
  align-content: center;
  margin-top: 0.75rem;
}

.checkbox {
  margin: 0;
}

.checkboxLabel {
  font-size: 13px;
}

.checkboxLink {
  color: black;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.submit {
  width: 295px;
  height: 54px;
  font-size: 14px;
  letter-spacing: 1.25px;
  font-weight: bold;
  margin-top: 2rem;
}

.submitPending div {
  top: -5px;
}

.inputPasswordRoot {
  position: relative;
}

.datePicker {
  border: solid 1px var(--color-light-gray);
  border-radius: 8px;
  padding: 12px 0;
  height: 34px;

  > :nth-child(2) {
    font-size: 13px;
    padding: 0 12px 3px;
    margin-bottom: 0;

    > :first-child {
      margin-bottom: 3px;
    }
  }

  input {
    border: none !important;
    height: 18px !important;
    padding-left: 0 !important;
    border-radius: 0;
    outline: none !important;
    font-weight: 500;
  }

  svg {
    top: -9px !important;
  }

  :global(.react-datepicker-wrapper) {
    width: 100%;
  }

  :global(.react-datepicker__input-container div) {
    width: 100%;
  }
}

.dropdownBlock {
  border: solid 1px var(--color-light-gray);
  border-radius: 8px;
  padding: 12px 0;
  height: 34px;

  .label {
    position: relative;
    top: -3px;
    font-size: 13px;
    padding: 0 12px 3px;
    line-height: 1.23;
    color: var(--color-dark-gray);
  }

  button {
    border: none;
    height: 16px;
    font-weight: 500;
  }

  button ~ div {
    top: 2rem;
  }

}

.otherLanguage {
  input {
    height: 18px !important;
  }
}

.interpreter {
  border-radius: 8px;
  padding: 20px;
  background: var(--color-white-smoke);

  &Label {
    margin-bottom: 16px;
    font-weight: 500;
  }
}

.radioBtn {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.dropdownCountry {
  input {
    border: none !important;
    height: 20px !important;
    padding-left: 12px;
    font-weight: 500;
  }

  & > div > div > div > div {
    &:nth-child(2) {
      margin-top: 12px;
    }
  }
}

.zipCodeInput {
  input {
    text-transform: uppercase;
  }

  ::-webkit-input-placeholder {
    text-transform: none;
  }

  ::-moz-placeholder {
    text-transform: none;
  }

  :-ms-input-placeholder {
    text-transform: none;
  }

  ::placeholder {
    text-transform: none;
  }
}
