.buttonContainer {
  display: flex;
  justify-content: flex-end;
  background-color: var(--color-gray);
}

.blankBackground {
  background-color: var(--color-white);
}

.submit {
  font-weight: 500;
  width: 11.25rem;
  height: 2.7rem;
  margin: 32px;
}
