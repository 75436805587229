@use "src/styles/mixins" as m;

.notificationPanel {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1rem;
}

.scroll {
  padding-left: 20px;
  margin-left: -20px;
  margin-top: 2rem;
  height: 80vh;
  overflow-y: auto;
  @include m.scrollbar();
}

.notificationControl {
  display: flex;
}

.notificationButton {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  cursor: pointer;
  margin-left: 24px;
  display: flex;
  align-items: center;

  svg {
    width: 8px;
    margin-left: 7px;
  }
}

.iconContainer {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.7rem;
}
