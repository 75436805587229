.body {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 30px;
  padding-bottom: 40px;
  list-style: none;
  color: var(--color-secondary);

  li {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    text-align: left;
  }

  p {
    padding-left: 100px;
  }
}

.goalIcon {
  width: 40px;
  height: auto;
  position: absolute;
  top: 50%; // Set top to 50% of the parent li element
  transform: translateY(-50%); // Move it up by 50% of its own height
  left: 35px;
}
