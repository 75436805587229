.dropdown {
  margin-bottom: 1rem;
  max-width: 470px;

  & input, & input:focus {
    height: 3rem !important;
    font-size: 16px;
  }

  & [class*='option'] {
    font-size: 16px;
  }
}

.dates {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(227px, max-content));
  grid-column-gap: 16px;
  grid-row-gap: 5px;
  margin-bottom: 2rem;
}

.inputContainer {
  position: relative;
  cursor: pointer;
}

.input {
  font-size: 1rem;
  width: calc(225px - 1rem);
  height: calc(3rem - 2px);
  border-radius: 8px;
  border: solid 1px var(--color-light-gray);
  padding-left: 1rem;

  &:focus {
    border: solid 1px var(--color-black);
  }
}

.icon {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.doseTitle {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.dateContainer {
  width: min-content;
}

.addAnother {
  margin: 0 1rem;
}

.vaccineContainer {
  display: flex;
}

.closeIcon {
  margin-left: 1rem;
  margin-top: 1rem;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}
