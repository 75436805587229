@use "src/styles/variables" as v;

.root {
  --pre-screening-layout-padding: 5rem;

  display: grid;
  justify-items: center;
  grid-template-rows: min-content 1fr;
  width: 100%;
  margin: 0 auto;

  grid-template-areas:
          "header"
          "main"
          "footer";
}

.footer {
  grid-area: footer;
  width: 100%;
  padding: 0;
}

.main {
  margin-top: 2em;
  grid-area: main;
  margin-bottom: 4em;
  padding: 0 var(--pre-screening-layout-padding);
  width: calc(100% - var(--pre-screening-layout-padding) * 2);

  @media (max-width: v.$hide-asides-width) {
    height: inherit;
    margin-bottom: 2em;
  }
}
