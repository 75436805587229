.root {
  width: 100%;
  height: 4px;
  border-radius: 50px;
  background: var(--color-whisper);
  position: relative;
}

.progressBar {
  position: absolute;
  height: 100%;
  background-color: var(--color-success);
  border-radius: 50px;
  -webkit-transition: width 1s;
  transition: width 1s;
}
