.input {
  opacity: 0;
  position: absolute;
  pointer-events: none;

  & + *::before {
    $size: 18px;
    content: '';
    box-sizing: border-box;
    display: inline-block;
    vertical-align: bottom;
    width: $size;
    height: $size;
    border-radius: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: gray;
  }

  &:checked + *::before {
    background: radial-gradient(circle, var(--color-accent) 0%, var(--color-accent) 40%, transparent 45%, transparent)
      center center;
    border-color: var(--color-accent);
  }
}

.label {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.3rem;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.verticalLabel {
  display: flex;
  flex-direction: column-reverse;
}

.subLabel {
  font-size: 13px;
  color: var(--color-dark-gray);
  display: block;
}
