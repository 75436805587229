.actions {
  position: absolute;
  right: 0;
  top: -10.4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cancel {
  font-weight: bold;
  font-size: 0.8rem;
  cursor: pointer;
  width: 80px;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 1.16px;
}

.button {
  height: 40px;
  font-weight: bold;
  align-items: center;
  font-size: 0.8rem;
  cursor: pointer;
  letter-spacing: 1.16px;
  overflow: hidden;
}

.fields {
  display: grid;
}

.field {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 2rem;
  padding-bottom: 2rem;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-lynx-white);
  }
}

.label {
  padding-left: 2rem;
  font-size: 1rem;
  font-weight: 500;
}

.value {
  padding-right: 2rem;
  font-size: 1rem;
  overflow-wrap: anywhere;
}

.dropdown input {
  margin-bottom: 7px;
}

.inputField {
  input {
    height: 48px !important;
  }
}

.errorField {
  margin-top: 8px;
}
