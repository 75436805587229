.root {
  display: grid;
  grid-row-gap: 1rem;
}

.pageTitle {
  font-weight: bold;
  font-size: 32px;
}

.weekButtonLeft {
  transform: rotate(180deg);
}

.weeklyReportTitle {
  display: block;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 12px;
  padding-top: 32px;
  margin-top: 16px;
  border-top: 1px solid var(--color-lynx-white);
}

.weeklyReportContent {
  width: 250px;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
}

.weeklyReportDates {
  font-size: 24px;
  font-weight: 500;
}

.weeklyReportButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  background: inherit;
}

.weeklyReportButtonLeft {
  transform: rotate(180deg);
}





