.diseaseInfo {
  display: flex;
  justify-content: center;
  width: 100%;
}

.logo {
  background: url('../../../assets/logo/logo.png') no-repeat;
  background-size: contain;
  height: 25px;
  margin: 32px auto 97px;
}

.content {
  width: 444px;
  margin: 0 auto;
}

.mainTitle {
  font-size: 32px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 40px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.form {
  display: grid;
  grid-row-gap: .75rem;
}

.submit {
  font-size: 14px;
  font-weight: bold;
  width: 295px;
  height: 54px;
  margin: 0 auto;
  display: inherit;
}

.promocodeWrapper {
  margin-bottom: 25px;
}

.promocodeField {
  position: relative;
  margin-bottom: 10px;
}

.promocodeValidate {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border-radius: 8px;
  background: #ffba00;
  color: #000;
}

.promocodeValidate:hover {
  background: #f2b000;
}

.cardElement {
  margin-bottom: 25px;
}
