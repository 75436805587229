@use "src/styles/variables" as v;

$border-size: 4px;

@mixin navMenuItem {
  border-radius: 0 8px 8px 0;
  padding: 18px 16px 18px 36px;
  &:hover {
    background-color: var(--color-very-light-grey);
  }
}

.navItem {
  white-space: pre-wrap;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  box-sizing: border-box;
  border-left: $border-size solid transparent;
  color: var(--color-black);
}

.navLink {
  @include navMenuItem;
  text-decoration: none;
}

.navLinkActive {
  background-color: var(--color-white-nav-link);
  border-left: $border-size solid var(--color-accent);
  &:hover {
    background-color: var(--color-white-nav-link) !important;
  }
}

.content {
  @include navMenuItem;
  display: grid;
  align-items: center;
  gap: 1rem;
  grid-template-columns: min-content auto;

  @media (max-width: v.$hide-asides-width) {
    &:not(.forceShowLabel) {
      gap: 0;
      display: block;
      grid-template-columns: min-content;

      .label {
        display: none;
      }
    }
  }

  @media (min-width: calc(v.$hide-asides-width + 1px)) {
    &.forceShowLabel {
      gap: 0;
      display: block;
      grid-template-columns: min-content;

      .label {
        display: none;
      }
    }
  }
}

.subItemWrap {
  display: grid;
  align-items: center;
}

.subNavItem {
  padding-left: 72px;
}

.navGroupIconDown {
  transform: rotate(90deg);
}

.icon {
  position: relative;
}

.bubble {
  $size: 6px;
  position: absolute;
  top: -1px;
  left: 11px;
  width: $size;
  height: $size;
  border: solid 2px var(--color-gray);
  border-radius: $size;
  background-color: var(--color-success);
}

.navItem:hover .bubble {
  border: solid 2px var(--color-very-light-grey);
}
