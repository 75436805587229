.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 30px 0;

  .Subtitle {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 10px 0 5px 0;
  }

  .SmallText {
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 1.5rem;
    color: var(--color-burgundy);
  }

  .TextArea {
    width: 80%;
    margin-top: 10px;
  }
}
