.root {
  padding-top: 40px;
  padding-bottom: 40px;
  flex-flow: column nowrap;
}

.title {
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 32px;
  color: var(--color-black);
}

.progressBar {
  width: 100%;
  padding-bottom: 60px;
}

.progressLine {
  width: 100%;
  height: 6px;
  border-radius: 20px;
  background: linear-gradient(to right, var(--color-danger), var(--color-accent) 15%, var(--color-accent) 30%, var(--color-accent) 70%, var(--color-green) 84%, var(--color-green));
}

.slider {
  position: relative;
  width: 100%;
}

.sliderTriangleWrapper {
  position: absolute;
  left: 0;
  top: 22px;
  -webkit-transition: width 1s;
  transition: width 1s;
  max-width: 100%;
  min-width: 0;
  text-align: right;
}

.sliderTriangle {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 13px 18px 13px;
  border-color: transparent transparent var(--color-accent) transparent;
}

.sliderTextWrapper {
  position: absolute;
  left: 0;
  top: 46px;
  -webkit-transition: width 1s;
  transition: width 1s;
  max-width: 100%;
  min-width: 0;
  text-align: right;
}

.sliderText {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: var(--color-black);
  white-space: nowrap;
  margin-top: 0;
  margin-bottom: 0;
}

.print {
  font-size: 24px;
  background: transparent;
  border: 1px solid var(--color-lynx-white);
  padding: 20px 18px 10px;

  .title {
    font-size: 23px;
    margin-bottom: 15px;
  }

  .sliderTriangleWrapper {
    top: 10px;
  }

  .sliderTextWrapper {
    top: 32px;
  }
}
