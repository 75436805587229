@use 'src/styles/typography' as t;
@use 'src/styles/variables' as v;

.wrapperRecipient {
  max-width: 50%;
  background-color: var(--color-background);
  margin-right: auto;
  margin-bottom: 0.25rem;
  padding: 0.5rem;
  min-width: 50px;
}

.wrapper {
  max-width: 50%;
  background-color: var(--color-accent);
  margin-left: auto;
  margin-bottom: 0.25rem;
  padding: 0.5rem;
  min-width: 50px;
}

.content {
  align-items: center;
}

.contentRecipient {
  align-items: center;
}

.title {
  @include t.text-overflow(1rem);
  grid-area: title;
  font-weight: 500;
  font-size: 0.75rem;
}

.authorInfo {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  overflow-wrap: break-word;
}

.date {
  grid-area: date;
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.text {
  grid-area: text;
  overflow-wrap: break-word;
  font-size: 16px;
  font-weight: 400;

  & > span {
    white-space: break-spaces;
  }
}

.icon {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;

  min-width: 0.8rem;
  min-height: 0.8rem;
  height: 0.8rem;
  width: 0.8rem;
}
