.trackingList {
  padding: 0;
  margin: 0 0 23px 0;
  list-style: none;
}

.trackingListTitle {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-black);
  margin-bottom: 10px;
}

.trackingListGroup {
  display: flex;
  flex-direction: column;
}

.trackingListItem {
  display: flex;
  align-items: center;
  padding: 13px 16px;
  border-radius: 8px;
  border: solid 1px var(--color-lynx-white);
  margin-bottom: 8px;
}

.trackingListItem a, .trackingListGroup a {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-black);
  text-decoration: underline;
}

.trackingListGroup a {
  margin-bottom: 6px;
}

.trackingListGroup span {
  font-size: 13px;
  color: var(--color-dark-gray);
}

.checkboxWrap {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 16px;
  color: var(--color-dark-gray);
}

.checkboxWrapDone {
  color: var(--color-green);
}

.checkbox {
  margin-left: 10px;
}
