.card {
  width: 676px;
  max-width: 80vw;
}

.headerRootClassName {
  padding: 24px 24px 0;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.date {
  margin-top: 5px;
  font-size: 13px;
  color: var(--color-dark-gray);
}

.contentRootClassName {
  padding: 0 24px 24px;
}


.details {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
}

.detailLabel {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-black);
}

.detailValue {
  margin-top: 12px;
  font-size: 16px;
  color: var(--color-black);
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.note {
  margin-top: 32px;
}

.noteValue {
  white-space: pre-line;
}

