.section {
  display: grid;
  grid-row-gap: 0.5rem;
}

.sectionHeader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  padding: 0 0 20px 0;
}

.sectionLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
}

.singleLink {
  justify-content: flex-end;
}

.selectButton {
  font-weight: 500;
  font-size: 1rem;
  text-decoration: underline;
  background-color: inherit;
  cursor: pointer;
}

.list {
  gap: 1.25rem
}

.mountain {
  max-height: 567px;
  display: flex;
  justify-content: center;
  & > svg {
    width: 100%;
    height: auto;
  }
}
