.root {
  position: relative;

  &:hover.enabled .tooltip,
  &:active.enabled .tooltip {
    display: block;
  }

  .content {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .tooltip {
    position: fixed;
    z-index: 10;
    display: none;
    padding: 12px;
    font-size: 13px;
    color: var(--color-black);
    border-radius: 4px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.13);
    border: solid 1px var(--color-light-gray);
    background-color: var(--color-white);
    margin-top: 10px;
  }
}
