.card {
  position: relative;
  z-index: 1;
  width: 22.5rem;
  padding: 2.5rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 26.75rem;

  .cardHeader {
    font-size: 1.5rem;
    color: var(--color-gray-title);
    font-weight: 400;
  }

  .cardInfoMessage,
  .cardActionLink {
    font-size: 0.875rem;
    font-weight: 400;
  }

  .centerCardInfo {
    text-align: center;
  }

  .cardInfoMessage {
    color: var(--color-gray-title);
  }

  .cardActionLink {
    color: var(--color-link);
    cursor: pointer;
    text-decoration: none;

    &.disable {
      cursor: not-allowed;
      color: var(--color-grey-link);
    }
  }

  .cardInfoMessageWithLink {
    display: flex;
    justify-content: center;
    gap: 0.75rem;
    flex-wrap: wrap;
  }

  .cardContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .cardForm {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    position: relative;
  }

  .cardInput,
  .cardButton {
    height: 3rem;
  }

  .cardError {
    position: absolute;
    bottom: -1.875rem;
  }

  .cardConfirmed {
    font-size: 1.5rem;
    color: var(--color-gray-title);
    font-weight: 500;
  }
}

.input {
  width: 100%;
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: none;
  background: none;
  color: black;
  font-size: 1rem;
  z-index: 1000;


  &:hover {
    cursor: pointer;
  }
}
