.checkbox {
  display: none;
}

.inputLabel {
  cursor: pointer;
  color: var(--color-black);
  border-radius: 4px;
  border: 1px solid var(--color-black);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox:checked+label {
  color: var(--color-black);
  background-color: var(--color-sand);
  border: 2px solid var(--color-accent);
  font-weight: 500;
  box-shadow: none;
}

.checkbox:disabled+label {
  box-shadow: none;
  cursor: default;
}

