.root {
  position: relative;
  width: 100%;
}

.label {
  font-size: .75rem;
  color: var(--color-dark-gray);
  margin-bottom: .5rem;
  display: inline-block;
}

.input {
  width: 100%;
  font-size: 1rem;
  border-radius: 8px;
  border: 1px solid var(--color-light-gray);
  padding: 1rem .75rem;
  box-sizing: border-box;

  &:focus {
    border: solid 1px var(--color-black);
    outline: none;
  }

  &::placeholder {
    font-size: 1rem;
    color: var(--color-dark-gray);
  }

  &:not(:focus) + .dropdown {
    transition: visibility linear .25s;
    visibility: hidden;
  }
}

.dropdown {
  position: absolute;
  z-index: 2;
  list-style-type: none;
  margin: .25rem 0 0;
  padding: .5rem 0;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 24px 53px 0 rgba(0, 0, 0, 0.27);
  background-color: var(--color-white);
}

.option {
  padding: 1.25rem .75rem;
  font-size: 1rem;
  cursor: pointer;
  margin: 0;

  &:hover {
    background-color: var(--color-grey-secondary);
  }
}
