@use "src/styles/mixins" as m;

.addItem {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 25px 0 15px;
  box-sizing: border-box;
  border: 1px solid var(--color-black);
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;

  span {
    margin-right: 8px;
    color: var(--color-dark-gray);
  }
}

.dropdownContainer {
  display: none;
  position: absolute;
  padding-top: 5px;
  box-shadow: 0 24px 53px 0 rgba(0, 0, 0, 0.27);
  border-radius: 0.5rem;
  left: 0;
  right: 0;
  max-height: 400px;
  overflow: auto;
  z-index: 5;
  background-color: var(--color-white);
}

.dropdownContainerOpened {
  display: block;
  @include m.scrollbar()
}

.dropdownContainerSmall {
  border-radius: 0 0 15px 15px;
}

.dropdownContainerDefault {
  border: none;
  box-shadow: 0 25px 40px -10px rgba(0, 0, 0, 0.24);
  border-radius: 0 0 10px 10px;
}

@media (max-height: 649px) {
  .dropdownContainer {
    max-height: 160px;
  }
}

@media (min-height: 650px) and (max-height: 899px) {
  .dropdownContainer {
    max-height: 200px;
  }
}

@media (min-height: 900px) and (max-height: 1100px) {
  .dropdownContainer {
    max-height: 320px;
  }
}
