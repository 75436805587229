.content {
  border-radius: 20px;
}

.mainTitle {
  margin-bottom: 8px;
}

.lastLogin {
  margin-bottom: 32px;
  font-size: 13px;
  color: var(--color-dark-gray);
}

.tabs {
  display: flex;
  font-size: 1rem;
}

.tab {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  color: var(--color-dark-gray);
  margin-right: 2rem;
  font-weight: 500;
  text-decoration: none;
}

.activeTab {
  color: var(--color-black);
  border-bottom: 2px solid var(--color-black);
  background-color: var(--color-white);
}

.separator {
  height: 1px;
  width: 100%;
  background-color: var(--color-background);
  margin-bottom: 2rem;
}

.mainContainer {
  bottom: 2px;
}

.tabLink {
  text-decoration: none;
}
