.container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-bottom: 1.5rem;
}

.text {
  font-size: 0.8rem;
  color: var(--color-dark-gray);
  margin-bottom: 1rem;
}
