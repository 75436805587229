@use "sass:math";

.card {
  width: 676px;
  max-width: 80vw;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  font-size: .9rem;
  white-space: nowrap;
}

.statusText {
  margin-left: 16px;
}

.danger {
  color: var(--color-danger);
}

.success {
  color: var(--color-success);
}

.listItemTitle {
  display: flex;
  align-items: center;
}

.listItemIcon {
  margin-right: 16px;
}

.complete {
  background-color: var(--color-success);
  border-radius: 100%;
  font-weight: 100;
  $size: 1.2rem;
  height: $size;
  width: $size;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: math.div($size, 2);
    height: math.div($size, 2);
    padding: 0;
  }
}

.taskCourseTipContainer {
  padding: .5rem 1rem;
  box-sizing: border-box;
  background-color: var(--color-sand);

  display: inline-grid;
  align-items: end;
  grid-template-columns: min-content auto;
  gap: .25rem;
  border-radius: .25rem;

  margin-bottom: 1rem;
}

.taskCourseTipIcon {
  $size: .9rem;
  height: $size;
  width: $size;
  padding-right: 0;
}

.taskCourseTip {
  color: inherit;
  font-size: 13px;
}

.taskCourseLink {
  text-decoration: none;
}

.taskCourseButton {
  letter-spacing: 1.16px;
  background-color: var(--color-white);
  border: solid 1px var(--color-black);
  color: var(--color-black);
  margin-bottom: 16px;

  &:hover {
    background: var(--color-very-light-grey);
  }

  &Icon {
    margin-right: 8px;
  }
}

.taskCourseText {
  max-width: 522px;
  margin-bottom: 12px;
}
