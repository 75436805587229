@use "src/styles/variables" as v;
@use "src/styles/mixins" as m;

.root {
  position: relative;
  display: grid;
  width: 100%;
  max-width: 100vw;
  height: 100%;

  grid-template-areas:
          "header header header"
          "leftSidebar main rightSidebar"
          "footer footer footer";
  grid-template-rows: min-content auto min-content;
  justify-content: space-between;
  justify-items: stretch;
  grid-template-columns: min-content minmax(auto, 1024px) min-content;
  column-gap: 2em;
  row-gap: .5em;

  @media (max-width: v.$hide-asides-width) {
    row-gap: 0;
    grid-template-columns: 0 1fr 0;
  }
}

.header {
  grid-area: header;
  @media (max-width: v.$hide-asides-width) {
    z-index: 1000;
  }
}

.leftSidebar {
  grid-area: leftSidebar;
  background-color: var(--color-gray);
  height: 100%;
  box-sizing: border-box;
  padding-right: 16px;
  width: v.$left-aside-width;
  transition: width .4s;
  @include m.scrollbar();

  @media (max-width: v.$hide-asides-width) {
    overflow-x: hidden;
    display: grid;
    position: sticky;
    width: 0;
    left: 0;
    top: .25em;
    padding-right: 0;
    z-index: 999;
  }

  @media (max-width: v.$hide-asides-width) {
    &.mobileMenuOpen {
      width: v.$left-aside-width;
      padding-right: 16px;
    }
  }

  @media (min-width: v.$hide-asides-width) {
    &.desktopMenuOpen {
      width: v.$left-aside-width;
    }
  }
}

.rightSidebar {
  grid-area: rightSidebar;
  background-color: var(--color-gray);
  width: v.$right-aside-width;
  box-sizing: border-box;
  transition: all .4s;
  @include m.scrollbar();

  @media (max-width: v.$hide-asides-width) {
    position: absolute;
    width: 0;
    right: 0;
    height: 100%;
    z-index: 999;
    overflow: hidden;
  }

  @media (max-width: v.$hide-asides-width) {
    &.mobileRightPanelOpen {
      width: v.$right-aside-width;
    }
  }

  @media (min-width: v.$hide-asides-width) {
    &.desktopRightPanelOpen {
      width: v.$right-aside-width;
    }
  }
}

.main {
  margin-top: 2em;
  grid-area: main;
  margin-bottom: 4em;

  @media (max-width: v.$hide-asides-width) {
    height: inherit;
    margin-bottom: 2em;
  }
}

.footer {
  grid-area: footer;
}

.root .overlay {
  @media (min-width: calc(v.$hide-asides-width + 1px)) {
    display: none;
  }
}
