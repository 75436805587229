.table {
  border-collapse: collapse;
  width: 100%;
}

.tableHeader {
  font-size: .8em;
  text-align: left;
  font-weight: 500;
  padding: 1rem 2rem;
  background-color: var(--color-gray);
  color: var(--color-dark-gray);
  text-transform: uppercase;
}

.tableCell {
  font-size: 1rem;
  padding: 1rem 2rem;
  border-top: 1px solid var(--color-lynx-white);
  align-content: center;
}

.noResultsContainer {
  background-color: var(--color-white-smoke);
  border-radius: .5em;
  gap: 1em;
}

.noResultsMessage {
  color: var(--color-dark-gray);
  font-size: 1rem;
}

.noResultsButton {
  font-size: 1rem;
  text-decoration: underline;
  cursor: pointer;
}

.moreButton {
  border: none;
  background: none;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  padding: 1em 0;
  box-sizing: border-box;
}

.moreCard {
  width: 125px;
  box-shadow: 0 24px 53px 0 rgba(0, 0, 0, 0.27);
}

.moreList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.moreListItem {
  margin: 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-background);
  }
}

.moreListItemContainer {
  padding: 1rem;
}

.moreListItemButton {
  border: none;
  background: none;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
