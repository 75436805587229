.switchContainer {
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  z-index: 0;
}

.switchOption {
  position: relative;
  font-size: 1rem;
  color: var(--color-dark-gray);
  cursor: pointer;
}

.switchOptionActive {
  color: var(--color-accent);

  &::after {
    content: '';
    position: absolute;
    border-bottom: solid 2px var(--color-accent);
    bottom: calc(-1rem - 1px);
    left: 0;
    width: 100%;
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: var(--color-lynx-white);
  margin-bottom: 1.5rem;
}

.dropdownArea {
  position: absolute;
  right: 2rem;
  top: 1rem;
  height: 1.5rem;
  width: 4rem;
}

.noData,
.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 11.8rem auto;
  font-size: 13px;
  color: var(--color-dark-gray);
}

.dropdownButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 12px;
  height: 1.5rem;
  background-color: var(--color-white);
  font-size: 1rem;
  cursor: pointer;
  color: inherit;
}

.chevronUp {
  transform: rotate(-90deg);
}

.chevronDown {
  transform: rotate(90deg);
}

.optionsContainer {
  width: 7.3rem;
  border-radius: 8px;
  background-color: var(--color-white);
  z-index: 1;
  box-shadow: 0 24px 53px 0 rgba(0, 0, 0, 0.27);
}

.option {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 1rem;
  padding-left: 12px;
  cursor: pointer;
  height: 2.5rem;
}

.optionInactive {
  color: var(--color-dark-gray);
  cursor: auto;
}

.chartContainer {
  padding-left: 2rem;
  padding-right: 2rem;
}
