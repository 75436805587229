.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;


  .button {
    border-radius: 0.5rem;
  }
}
