

.modalOut {
  text-decoration: underline;
  color: var(--color-grey-link);
  cursor: pointer;
}


.noMyProvider {
  @extend .modalOut;
  text-align: center;
}

.actions {
  position: absolute;
  right: 0;
  top: -10.4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.root {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}



.cancel {
  font-weight: bold;
  font-size: 0.8rem;
  cursor: pointer;
  width: 5rem;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 0.073rem;
}

.button {
  height: 2.5rem;
  font-weight: bold;
  align-items: center;
  font-size: 0.8rem;
  cursor: pointer;
  letter-spacing: 0.073rem;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
}
