.root {
  margin-bottom: 32px;
  border-radius: 8px;
}

.content {
  padding: 32px;
  box-sizing: border-box;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.09);
}

.header {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.attentionIcon {
  display: block;
  width: 44px;
  margin-top: -12px;
}
.date {
  position: absolute;
  right: 0;
  top: 34px;
  min-width: 100px;
  color: var(--color-dark-gray);
}

.iconArrow {
  width: 28px;
  height: 28px;
  margin-left: auto;
  cursor: pointer;
  transform: rotate(180deg);
}

.iconArrowDown {
  transform: rotate(0);
}

.wrapper {
  width: 100%;
  margin: 0 16px 0 16px;
}

.expandText {
  color: var(--color-link);
  cursor: pointer;
  min-width: 80px;
}

.title {
  display: block;
  font-weight: 500;
  margin-bottom: 8px;
}

.preview {
  color: var(--color-ford-gray);
  font-size: 14px;
  display: flex;
  align-items: baseline;
  word-break: break-all;
  margin-right: 100px;

  div, p {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-box-orient: vertical;
    max-height: 1.6em;
    line-height: 1.6em;
    -webkit-line-clamp: 1;

    & > br {
      display: none;
    }
  }

  span {
    color: var(--color-link);
    cursor: pointer;
    min-width: 80px;
  }
}

.collapsedHeader {
  margin-bottom: -10px;
}

.description {
  font-size: 14px;
  font-weight: normal;

  div, p {
    margin-bottom: 16px;
    max-width: 602px;
    line-height: 1.5;
    font-size: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    list-style: none;
  }

  a {
    color: var(--color-black);
  }
}

.hideDescription {
  display: none;
}
