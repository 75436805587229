.medicationDropdown {
  input, input:focus {
    border-color: var(--color-black) !important;
    height: 40px !important;
    font-size: 16px !important;
  }

  :global(.single-dropdown__dropdown-container) {
    border: none;
    border-radius: 8px;
    box-shadow: 0 24px 53px 0 rgba(0, 0, 0, 0.27);
  }

  :global(.single-dropdown__option--selected) {
    background-color: var(--color-grey-secondary);
    color: var(--color-dark-gray);
  }

  :global(.single-dropdown__input--dropdown-error) {
    border-color: var(--color-danger) !important;
  }

  :global(.single-dropdown__option-text) {
    font-size: 1rem;
  }
}

.formElements {
  padding: 20px;
}

.error {
  margin-top: 10px;

  div {
    font-size: 13px;

    svg {
      margin-right: 4px;
    }
  }
}

.rowWrapper {
  margin-bottom: 25px;
}

.row {
  margin-bottom: 0;
}
