.details {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
}

.detailLabel {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-black);
}

.detailValue {
  margin-top: 12px;
  font-size: 16px;
  color: var(--color-black);
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.subHeader {
  margin-top: 1.8rem;

  font-size: 14px;
  font-weight: normal;
  color: var(--color-secondary);
}

.valueDescription {
  margin-top: 0.4rem;
  font-size: 0.8rem;
  font-weight: normal;
  color: var(--color-black);
}

.listItemValue {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

