.checkbox {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    gap: 8px;
  }
}

.readonly {
  display: flex;
  margin-bottom: 30px;
}

