.root {
  margin-bottom: 24px;
  position: relative;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.content {
  padding: 6.5px 16px;
  border-radius: 20px;
  background-color: var(--color-very-light-grey);
  display: flex;
  align-items: center;
  cursor: pointer;
  width: max-content;

  &Active, &:hover {
    background-color: var(--color-btn-dd-hover);
  }
}

.value {
  font-size: 16px;
  font-weight: normal;
  color: var(--color-black);
  margin-right: 16px;
}

.icon {
  display: flex;
  border: none;
  background-color: inherit;
  align-items: center;
  cursor: pointer;

  path {
    stroke: var(--color-close-icon);
    stroke-width: 1.5px;
  }
}

.iconSelected {
  path {
    stroke: var(--color-black);
  }
}

.dropdown {
  border-radius: 8px;
  background-color: var(--color-white);
  position: absolute;
  margin-top: 8px;
  z-index: 1;
  min-width: 100%;

  &Shadow {
    box-shadow: 0 24px 53px 0 rgba(0, 0, 0, 0.27);
  }
}
