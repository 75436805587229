.content {
  display: flex;
  width: 100%;
  height: 100%;
}

.info {
  font-size: 13px;
  color: var(--color-dark-gray);
  display: flex;
  align-items: center;
}

.circleProgress {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: solid 2px #00d9ba;
  box-sizing: border-box;
  transform: rotate(45deg);
  margin-right: 16px;
}

.thirdProgress {
  border-bottom-color: var(--color-bright-grey);
  border-left-color: var(--color-bright-grey);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: -3px;
    top: -3px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border-bottom: solid 2px var(--color-bright-grey);
    transform: rotate(308deg);
  }
}

.twoThirdsProgress {
  border-left-color: var(--color-bright-grey);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: -2px;
    top: -4px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border-bottom: solid 2px var(--color-bright-grey);
    transform: rotate(38deg);
  }
}
