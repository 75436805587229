.card {
  position: relative;
  width: 503px;
  z-index: 1;
}

.bottomWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 1rem;
  margin-top: 25px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > * {
    &:not(:first-child) {
      margin-left: 24px;
    }
  }
}

.input {
  margin-bottom: 8px;
}

.current {
  color: var(--color-dark-gray);
  font-size: 14px;

  span {
    font-weight: 500;
  }
}

.resendCodeTimer {
  color: var(--color-danger);
}
