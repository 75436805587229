@mixin paddingTop($multiplier: 1) {
  padding-top: .8rem * $multiplier;
}

@mixin paddingBottom($multiplier: 1) {
  padding-bottom: .8rem * $multiplier;
}

@mixin paddingRight($multiplier: 1) {
  padding-right: .8rem * $multiplier;
}

@mixin paddingLeft($multiplier: 1) {
  padding-left: .8rem * $multiplier;
}

@mixin padding($multiplier: 1) {
  padding: .8rem * $multiplier;
}

.root {
  @include padding;

  box-sizing: border-box;

  font-size: .8rem;
}

.gap0 {
  @include padding(0);
}

.gap1 {
  @include padding;
}

.gap2 {
  @include padding(2);
}

.gap3 {
  @include padding(3);
}

.gapTop0 {
  @include paddingTop(0);
}

.gapTop1 {
  @include paddingTop;
}

.gapTop2 {
  @include paddingTop(2);
}

.gapTop3 {
  @include paddingTop(3);
}

.gapBottom0 {
  @include paddingBottom(0);
}

.gapBottom1 {
  @include paddingBottom;
}

.gapBottom2 {
  @include paddingBottom(2);
}

.gapBottom3 {
  @include paddingBottom(3);
}

.gapRight0 {
  @include paddingRight(0);
}

.gapRight1 {
  @include paddingRight;
}

.gapRight2 {
  @include paddingRight(2);
}

.gapRight3 {
  @include paddingRight(3);
}

.gapLeft0 {
  @include paddingLeft(0);
}

.gapLeft1 {
  @include paddingLeft;
}

.gapLeft2 {
  @include paddingLeft(2);
}

.gapLeft3 {
  @include paddingLeft(3);
}

.center {
  display: grid;
  align-items: center;
  justify-items: center;
  text-align: center;
}
