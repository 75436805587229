.addButtonContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.addIcon {
  $size: 20px;
  height: $size;
  width: $size;
  margin-right: 0.5rem;
}

.buttonText {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.16px;
  text-transform: uppercase;
}
