@use "src/styles/variables" as v;

.wrapper {
  display: grid;
  grid-row-gap: 1rem;
  grid-template-rows: 1fr minmax(600px, auto);

  @media (max-width: v.$screen-responsive-width-low) {
    grid-template-rows: 1fr;
  }
}

.wrapperLoad {
  display: block;
}

.title {
  @media (max-width: v.$screen-responsive-width-low) {
    display: none;
  }
}

.root {
  display: grid;
  grid-template-columns: 323px 1fr;

  @media (max-width: v.$screen-responsive-width-low) {
    display: block;
  }
}

.cardEmptyText {
  background-color: var(--color-background);
  border-radius: 1.5rem;
  padding: .5rem 1.5rem;
  color: var(--color-dark-gray);
  font-size: .85rem;
  margin: 0 auto;
}

.emptyCard {
  @media (max-width: v.$screen-responsive-width-low) {
    display: none;
  }
}
