@use "sass:math";

@mixin size($multiplier: 3) {
  $size: 1.5rem + 0.5rem * $multiplier;

  width: $size;
  height: $size;

  svg {
    width: math.div($size, 2);
    height: math.div($size, 2);
    padding: 0;
  }
}

.complete {
  background-color: var(--color-success);
  border-radius: 100%;
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.size1 {
  @include size(1);
}

.size2 {
  @include size(2);
}

.size3 {
  @include size;
}

.size4 {
  @include size(4);
}
