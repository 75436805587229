.toggleSwitch {
  position: relative;
  margin-right: 10px;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;

  &Checkbox {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  &.Disabled {
    cursor: not-allowed;
  }

  &Label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid var(--color-dark-gray);
    border-radius: 12px;
    margin: 0;

    &.Disabled {
      pointer-events: none;
    }
  }

  &Inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.15s ease-in 0s;

    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 14px;
      color: var(--color-white);
      font-weight: bold;
      box-sizing: border-box;
    }

    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 10px;
      background-color: var(--color-accent);
      color: var(--color-white);
    }
  }

  &Inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: var(--color-white);
    text-align: right;
    color: var(--color-dark-gray);
    border: solid 1px var(--color-dark-gray);
    border-radius: 12px;
  }

  &Switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: var(--color-white);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid var(--color-dark-gray);
    border-radius: 12px;
    transition: all 0.15s ease-in 0s;
  }

  &Checkbox:checked + &Label {
    .toggleSwitchInner {
      margin-left: 0;
    }

    .toggleSwitchSwitch {
      right: 0;
    }
  }

  &.smallSwitch {
    width: 34px;

    .toggleSwitchInner {
      &:after,
      &:before {
        content: '';
        height: 20px;
        line-height: 20px;
      }
    }

    .toggleSwitchSwitch {
      width: 12px;
      right: 15px;
      margin: 4px;
    }
  }

  &.switchOn {
    .toggleSwitchDisabled {
      background-color: var(--color-white);
      cursor: not-allowed;

      &:before {
        background-color: var(--disabled-toogle-switch-color) !important;
        cursor: not-allowed;
      }
    }
  }

  &.switchOff {
    .toggleSwitchSwitch {
      background: var(--color-dark-gray);
    }

    .toggleSwitchDisabled {
      background-color: var(--disabled-toogle-switch-color);

      &:after {
        border: solid 1px var(--disabled-toogle-switch-color);
      }
    }
  }
}
