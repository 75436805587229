.noticeList {
  list-style: none;
  padding: 0;
}

.noticeListCaption {
  font-size: 13px;
  font-weight: 600;
  line-height: 1.62;
  margin-bottom: 12px;
}

.noticeListItem {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.noticeListLink {
  font-size: 13px;
  color: var(--color-grey-link);
}
