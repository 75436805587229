.root {
  object-fit: cover;
  border-radius: 100%;
  border: solid 1.5px var(--color-accent);
}

.placeholder {
  border-radius: 100%;
  border: solid 1.5px var(--color-accent);
  box-sizing: border-box;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;

  * {
    text-align: center;
  }
}

.green {
  background-color: var(--color-success);
  color: white;
}

.red {
  background-color: var(--color-danger);
  color: white;
}

.gray {
  background-color: var(--color-dark-gray);
  color: white;
}

.yellow {
  background-color: var(--color-accent);
  color: black;
}

.clickable {
  cursor: pointer;
}
