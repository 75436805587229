.noOutline {
  outline: none !important;
}

.overlay {
  position: relative;
}

.closeIcon {
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
}
