$border: 2px solid var(--color-light-gray);

@mixin singleDropDownNormalize($size: 48px) {
  input {
    &, &:focus {
      height: $size;

      border-radius: 8px;
      border: solid 1px var(--color-light-gray);
    }

    & ~ div {
      display: none;
    }
  }

  & > div > div:nth-child(2) {
    border-radius: 8px !important;
    border: solid 1px var(--color-light-gray) !important;
  }
}

.single-dropdown {
  @include singleDropDownNormalize();
  position: relative;

  &--disabled {
    .single-dropdown__input {
      color: var(--color-black);
      background-color: var(--color-gray);

      &:focus {
        border: $border;
      }
    }
  }

  &__main-container {
    display: flex;
    flex-direction: column;
  }

  &__label {
    padding: 0 0 7px 15px;
    line-height: 1.23;
    color: var(--color-gray);

    &--small {
      padding: 0 0 5px 10px;
    }
  }

  &__input-container {
    position: relative;

    &--default {
      box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.24);
      border-radius: 20px;
    }
  }

  &__input-container-search,
  &__input-container-input {
    position: relative;
  }

  &__input {
    height: 40px;
    border-radius: 20px;
    border: $border;
    outline: none;
    padding: 0 25px 0 15px;
    background-color: var(--color-white);
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    text-overflow: ellipsis;
    font-size: 1rem;

    &--default {
      height: 30px;

      border: 1px solid var(--color-light-gray);
    }

    &:focus {
      border-color: var(--color-light-yellow);
    }

    &--default-opened {
      border: none;
      border-bottom: solid 1px var(--color-light-gray);

      &:focus {
        border: none;
        border-bottom: solid 1px var(--color-light-gray);
        border-color: var(--color-gray);
      }
    }

    &--invalid {
      border-color: var(--color-danger) !important;

      &:focus {
        border-color: var(--color-danger) !important;
      }
    }

    &--dropdown-opened {
      border-radius: 20px 20px 0 0;

      &:focus,
      &--invalid {
        border: $border
      }
    }

    &--small {
      height: 30px;

      &.single-dropdown__input--dropdown-opened {
        border-radius: 15px 15px 0 0;
      }
    }

    &--placeholder {
      color: var(--color-dark-gray);
    }

    &--fake {
      display: flex;
      align-items: center;

      position: absolute;
      top: 0;
    }
  }

  &__icon {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    &--opened {
      transform: rotate(180deg);
    }

    &--small, &--default {
      top: 5px;
    }
  }

  &__form-select {
    display: none;
  }

  &__option {
    height: 40px;
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &--selected {
      color: var(--color-accent);
    }

    &:hover {
      background-color: var(--color-white-smoke);
    }

    &--small {
      height: 30px;
    }

    &--no-items {
      cursor: default;
      text-align: center;
    }
  }

  &__option-text {
    width: 100%;
    font-size: 1rem;
  }

  &__input--dropdown-error {
    border-color: var(--color-danger) !important;
    background-color: var(--color-danger-light) !important;
  }
}
