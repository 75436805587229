$size: 32px;
$margin: 8px;

.loading {
  height: $size + $margin;
  width: $size + $margin;
  position: relative;
  align-items: center;
  display: flex;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    height: $size;
    width: $size;
    margin: $margin;
    border-style: solid;
    border-width: 3px;
    border-radius: 50%;
    animation: loading 1.2s cubic-bezier(.12, .52, .48, .08) infinite;
    border-color: var(--color-accent) var(--color-accent) var(--color-accent) transparent;
  }
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
