.card {
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;

  &.unread {
    border-top: solid 2px;
    border-color: var(--color-green);

    p {
      font-weight: bold;
    }
  }

  &.selected {
    background-color: var(--color-light-green);
  }
}

.container {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}

.indicator {
  flex: 0 0 auto;
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  cursor: default;
  flex: 1;
}

.title {
  display: flex;
  align-items: center;
  margin: 0 0 8px 0;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.31;
  cursor: default;

  span, a {
    margin-right: 10px;
  }

  a {
    color: inherit;
  }
}

.time {
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.38;
  color: var(--color-dark-gray);
}

.text {
  padding: 0;
  margin: 0;
  font-weight: normal;
  text-align: left;
  font-size: 16px;


  &.collapse {
    width: calc(100% - 1.6rem);
    max-height: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      white-space: nowrap;
    }
  }

  a {
    color: inherit;
  }
}

.flag {
  position: absolute;
  top: 0.938rem;
  right: 0.938rem;
  width: 0.938rem;

  svg {
    width: 100%;
    height: 100%;
  }
}
