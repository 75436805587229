.root {
  display: grid;
  grid-row-gap: 1.5rem;
}

.dateGroupTitle {
  color: var(--color-dark-gray);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.dateGroup {
  display: grid;
  grid-row-gap: 1.5rem;
}

.messages {
  display: grid;
}
