.button {
  border-radius: 0.5rem;
}

.gridHeader {
  display: grid;
  grid-template-columns: 20% 80%;
  font-size: 13px;
  font-weight: 500;
  color: var(--color-dark-gray);
  border: solid 1px var(--color-lynx-white);
  padding: 14px 0 15px 32px;
  background-color: var(--color-gray);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.empty {
  padding: 33px 5px;
  border-radius: 8px;
  background-color: var(--color-white-smoke);
  font-size: 16px;
  color: var(--color-dark-gray);
  margin-top: 8px;
  text-align: center;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
