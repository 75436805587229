.dailyHeader {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.dailyHeader h2 {
  font-weight: 500;
  font-size: 24px;
}

.dailyDate {
  text-align: right;
  font-weight: 500;
}

.dailyDescription {
  color: var(--color-black);
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
}

.weekButton {
  align-self: center;
  display: grid;
  align-items: center;
  justify-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  box-sizing: border-box;
  padding-left: 4px;
  cursor: pointer;
  background: inherit;
}

.weekButtonLeft {
  transform: rotate(180deg);
  margin-right: 20px;
}

@media (max-width: 1440px) {
  .dailyDate {
    width: 110px;
  }
}

.week {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 42px;
}
