.date-picker .react-datepicker {
  background-color: transparent;
  border: transparent;
  box-shadow: 0 0 black;
}

.date-picker .react-datepicker__header {
  background-color: transparent;
}

.date-picker .react-datepicker__day-names {
  border: transparent;
}

.date-picker .react-datepicker__day {
  padding: 6px;
  border-radius: 25px;
}

.date-picker .react-datepicker__day-name {
  padding: 6px;
}
