.Root {
  padding: 0;
}

.card {
  width: 676px;
  max-width: 80vw;
}

.Header {
  background: linear-gradient(180deg, var(--color-light-teal) 50%, #ffffff 50%);
  border-radius: 0.5rem;
  padding: 2rem 2rem 0;

  div {
    color: var(--color-purple);
    font-weight: 500;
    &:last-child {
      margin-top: 20px;

      button {
        background: var(--color-accent);
        border: none;
      }
    }
  }
}

.grayBold {
  font-weight: bold;
  color: var(--color-gray-card)
}

.bold {
  font-weight: bold;
}

.content {
  font-size: 16px;
  padding: 2rem 2rem 0;
}

.PatientInfo {
  display: flex;
  justify-content: space-between;

  .patientName {
    font-size: initial;
  }

  .dateOfBirth {
    font-size: initial;
  }
}

.Info {
  color: var(--color-purple);
  font-weight: 500;
  margin-left: 5px;
}

.ThankYouText {
  color: var(--color-purple);
  font-weight: 500;
  margin-top: 10px;
}

.Order {
  display: flex;
}

.TextSpace {
  margin-top: 15px;
  padding: 0;
}

.Image {
  width: 200px;
}

.contentText p {
  font-size: 16px;
  line-height: 1.5;
}

.caption {
  border-top: 1px solid var(--color-background);
  font-size: 16px;
}

.captionTitle {
  font-size: initial;
  margin-bottom: 8px;
}

.providerName {
  font-size: initial;
  margin-bottom: 5px;
}

.providerPhone {
  font-size: initial;
}

.printButton {
  width: 6.3rem;
  height: 2.3rem;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 1.16px;
  padding: 0;
  background-color: var(--color-white);
  border: solid 1px var(--color-black);
  color: var(--color-black);
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--color-very-light-grey);
  }

  svg {
    margin-right: 8px;
  }
}

@media (max-width: 480px) {
  .printButton {
    padding: 10px;
  }
}

.print {
  display: none;
}

.LogoBackground {
  background-color: var(--color-light-teal);
  padding: 1rem 1rem 0;
}

.printLogo {
  width: 100px;
}

.printTitle {
  font-weight: bold;
  max-width: 80%;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--color-purple);
  padding-left: 1rem;
}

.printContent {
  font-size: 12px;
  padding: 0.3rem 1rem 0;
}

.printContentPatient {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.printPatientInfo {
  color: var(--color-purple);
  font-weight: 500;
  margin-left: 5px;
}

.printThankYouText {
  color: var(--color-purple);
  font-weight: 500;
  margin-top: 10px;
}

.printImage {
  width: 100px;
}

.printCaption {
  border-top: 1px solid var(--color-background);
  margin-top: 10px;
  padding-top: 10px;
}

.printPatientName,
.printDateOfBirth,
.printCaptionTitle,
.printProviderName,
.printProviderPhone {
  font-size: 9px;
}

.printContentText p {
  font-size: 9px;
  margin-bottom: 5px;
}
