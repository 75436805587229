.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contentWithThreeColumns {
  grid-template-columns: 1fr auto auto;
}

.statusGroup {
  position: absolute;
  left: -30px;
  bottom: -35px;
}

.wrapper {
  padding: 24px;
}

.title {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}

.withImage {
  display: flex;
  gap: 2rem;
}

.courseImage {
  align-items: center;
}

.completedItem {
  background-color: var(--color-success);
}

.image,
.greyCube {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 4px;
}

.hoverable {
  &:hover {
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.14);
    border: 1px solid transparent;
    cursor: pointer;
    padding-top: 5px;
  }
}

.gray {
  border-top: 6px solid var(--color-dark-gray);
}

.success {
  border-color: var(--color-success);
  background-color: var(--color-bg-green);
}

.highlight {
  border: 1px solid var(--color-accent);
  box-shadow: inset 0 0 0 2px var(--color-accent);
}

.hoverable.highlight {
  &:hover {
    border: 1px solid var(--color-accent);
    box-shadow: inset 0 0 0 2px var(--color-accent), 0 8px 20px 0 rgba(0, 0, 0, 0.14);
  }
}

.metaText {
  color: var(--color-secondary);
  font-size: 13px;
  padding-bottom: 0.35rem;
  box-sizing: border-box;
  display: inline-block;
  white-space: pre-wrap;
}

.date {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 0.81rem;
  margin-top: 10px;
}

.date > :last-child {
  margin-left: 9px;
}

.danger {
  color: var(--color-danger);
}
