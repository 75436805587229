$color-danger: #e21d1d;
$color-success: #00d9ba;
$color-link: #1e6ee6;
$color-secondary: #505050;
$color-background: #e8e8e8;
$color-accent: #ffbb00;
$color-primary-yellow: #ffc000;
$color-accent-hover: #ffb300;
$color-dark-grey: #505050;
$color-light-yellow: #f6f5f1;
$color-lynx-white: #e7e7e7;
$color-sand: #fff8e6;
$color-gray: #fafafa;
$color-gray-title: #1e212a;
$color-black: #000000;
$color-silver: #dfdfdf;
$color-white: #ffffff;
$color-very-light-grey: #f1f1f1;
$color-grey-secondary: #f9f9f9;
$color-light-gray: #d5d5d5;
$color-danger-light: #fff0f0;
$color-darkest-grey: #232323;
$color-white-smoke: #f6f6f6;
$color-grey-link: #484848;
$color-green: #00bfa4;
$color-light-green: rgba($color-green, 0.1);
$color-bg-green: #fafffd;
$color-behr-goldfish: #ff9d00;
$color-dandelion: #ffd668;
$color-dark-orange: #ff8800;
$color-light-orange: #ffb900;
$color-night-rider: #2b2b2b;
$color-night-rider-translucent: rgba(#2b2b2b, 0.5);
$color-whisper: #ebebeb;
$color-gainsboro: #b9b9b9;
$color-grey-icon: #6f6f6f;
$color-grey-scroll: #b4b4b4;
$color-grey-scroll-bg-hover: #8b8686;
$color-silver-sand: #c1c6ca;
$color-alabaster: #fbfbfb;
$disabled-banner-color: #c2c2c2;
$disabled-toogle-switch-color: #c7c7c7;
$color-burgundy: #aa1059;
$color-burgundy-light: #d12476;
$color-greeting: #9e2459;
$color-bright-grey: #ececec;
$color-btn-dd-hover: #e2e2e2;
$color-white-nav-link: #eff9f7;
$color-close-icon: #222222;
$color-pink: #e22d2d;
$color-background-dark: #e9e9e9;
$color-light-teal: #c7e7de;
$color-purple: #9d225a;
$color-gray-card: #4b5251;
$color-border-lessons: #8d2d59;
$color-border-tasks: #6bb09d;
$color-light-pink: #f4eaef;
$color-light-blue: #67a4ee;
$color-achieve: #39b95f;

$screen-xs-max: 350px;
$screen-sm-min: 400px;
$screen-sm-max: 450px;
$screen-md-min: 700px;
$screen-md-max: 800px;
$screen-lg-min: 1000px;

$screen-responsive-width-low: 1019px;
$hide-asides-width: 1299px;
$left-aside-width: 324px;
$right-aside-width: 392px;
