@use 'src/styles/typography' as t;

.modal {
  border-radius: 14px;
  background-color: var(--color-background);
  padding: 1.25rem;
  min-width: 37.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.titleWrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 20px;
  line-height: 1.5;
  color: var(--color-black);
}

.goalImage {
  width: auto;
  height: 40px;
}

.goalContainer {
  cursor: pointer;
  color: #67a4ee;
  display: flex;
  align-items: center;
  gap: 10px;
}

.noCursor {
  cursor: auto;
}

.spinner {
  margin-right: 25px;
}

.noStyle {
  list-style-type: none;
}
