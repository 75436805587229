@use "src/styles/typography" as t;

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  color: var(--color-black);
  font-size: 20px;
  border-radius: 0 0 8px 8px;
  text-align: center;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.09);
  background-image: url("../../assets/member-frame/member-frame.svg");
  background-size: 124%;
}

.avatar {
  border-width: 3px;
  margin-bottom: 16px;
}

.greeting {
  @include t.Lora;
  display: flex;
  color: var(--color-greeting);
  flex-direction: column;
  align-items: center;
  position: relative;
  font-size: 26px;
  font-weight: 500;
}

.title {
  @include t.Lora;
  text-align: center;
  font-size: 20px;
  line-height: 1.5;
  width: 561px;
  background-color: var(--color-light-teal);
  padding: 16px 24px;
  color: var(--color-black);
  border-radius: 8px;
  margin-top: 20px;
}

.titleProvider {
  @extend .title;
  background-color: var(--color-dandelion);
  text-decoration: none;
  font-weight: bolder;
}

.link{
  color: var(--color-black)
}
