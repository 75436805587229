.root {
  font-size: 1rem;

  & input {
    border: solid 1px var(--color-light-gray);
    height: 40px !important;
    font-size: 1rem;

    &:focus {
      border: solid 1px var(--color-light-gray);
      height: 40px !important;
    }
  }

  :global(.single-dropdown__dropdown-container) {
    border: none;
    border-radius: 8px;
    box-shadow: 0 24px 53px 0 rgba(0, 0, 0, 0.27);
  }

  :global(.single-dropdown__option--selected) {
    background-color: var(--color-grey-secondary);
    color: var(--color-dark-gray);
  }

  :global(.single-dropdown__option-text) {
    font-size: 1rem;
  }
}

.input {
  font-size: 1rem;
  margin-top: 8px;
  width: 100%;

  & > input {
    width: 100% !important;
    border-radius: 8px;
    border: solid 1px var(--color-light-gray);
    margin-bottom: 0 !important;
    height: 40px !important;

    &:focus {
      border: solid 1px var(--color-light-gray);
    }
  }

  :global(.input__input--invalid) {
    background: var(--color-danger-light) !important;
  }
}

.error {
  margin-top: 10px;
}
