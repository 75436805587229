@use './variables' as v;
@use '../styles/mixins' as m;
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

@include m.resetDefaultIndentations;
@include m.setFontRobotoByDefault;

:root {
  --color-success: #{v.$color-success};
  --color-danger: #{v.$color-danger};
  --color-link: #{v.$color-link};
  --color-secondary: #{v.$color-secondary};
  --color-background: #{v.$color-background};
  --color-accent: #{v.$color-accent};
  --color-accent-hover: #{v.$color-accent-hover};
  --color-dark-gray: #{v.$color-dark-grey};
  --color-light-yellow: #{v.$color-light-yellow};
  --color-lynx-white: #{v.$color-lynx-white};
  --color-sand: #{v.$color-sand};
  --color-gray: #{v.$color-gray};
  --color-black: #{v.$color-black};
  --color-silver: #{v.$color-silver};
  --color-white: #{v.$color-white};
  --color-very-light-grey: #{v.$color-very-light-grey};
  --color-grey-secondary: #{v.$color-grey-secondary};
  --color-light-gray: #{v.$color-light-gray};
  --color-danger-light: #{v.$color-danger-light};
  --color-darkest-gray: #{v.$color-darkest-grey};
  --color-white-smoke: #{v.$color-white-smoke};
  --color-grey-link: #{v.$color-grey-link};
  --color-green: #{v.$color-green};
  --color-bg-green: #{v.$color-bg-green};
  --color-light-green: #{v.$color-light-green};
  --color-behr-goldfish: #{v.$color-behr-goldfish};
  --color-dandelion: #{v.$color-dandelion};
  --color-dark-orange: #{v.$color-dark-orange};
  --color-light-orange: #{v.$color-light-orange};
  --color-night-rider: #{v.$color-night-rider};
  --color-night-rider-translucent: #{v.$color-night-rider-translucent};
  --color-whisper: #{v.$color-whisper};
  --color-gainsboro: #{v.$color-gainsboro};
  --color-grey-icon: #{v.$color-grey-icon};
  --color-grey-scroll: #{v.$color-grey-scroll};
  --color-grey-scroll-bg-hover: #{v.$color-grey-scroll-bg-hover};
  --color-silver-sand: #{v.$color-silver-sand};
  --color-alabaster: #{v.$color-alabaster};
  --disabled-banner-color: #{v.$disabled-banner-color};
  --disabled-toogle-switch-color: #{v.$disabled-toogle-switch-color};
  --color-burgundy: #{v.$color-burgundy};
  --color-burgundy-light: #{v.$color-burgundy-light};
  --color-primary-yellow: #{v.$color-primary-yellow};
  --color-gray-title: #{v.$color-gray-title};
  --color-bright-grey: #{v.$color-bright-grey};
  --color-btn-dd-hover: #{v.$color-btn-dd-hover};
  --color-close-icon: #{v.$color-close-icon};
  --color-pink: #{v.$color-pink};
  --color-background-dark: #{v.$color-background-dark};
  --color-light-teal: #{v.$color-light-teal};
  --color-purple: #{v.$color-purple};
  --color-gray-card: #{v.$color-gray-card};
  --color-greeting: #{v.$color-greeting};
  --color-border-lessons: #{v.$color-border-lessons};
  --color-border-tasks: #{v.$color-border-tasks};
  --color-light-pink: #{v.$color-light-pink};
  --color-light-blue: #{v.$color-light-blue};
  --color-achieve: #{v.$color-achieve};
}
