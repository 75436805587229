.table {
  border-collapse: collapse;
  width: 100%;
}

.hidden {
  display: none;
}

.wrapper {
  margin-bottom: 40px;
}

.tableTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
}

.tableArrow {
  margin-left: .5rem;
  cursor: pointer;
  transform: rotate(180deg);
}

.tableArrowActive {
  transform: none;
}

.tableHeader {
  font-size: .8em;
  text-align: left;
  font-weight: 500;
  padding: 1rem 2rem;
  background-color: var(--color-gray);
  color: var(--color-dark-gray);
  text-transform: uppercase;
}

.rectangle {
  padding: 32px !important;

  .rectangleText {
    font-size: 1rem;
    color: var(--color-dark-gray);
  }
}

