.modalRoot {
  width: 31.625rem;
  border-radius: 8px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-white);
  padding: 2rem;

  p {
    text-align: center;
    padding: 1.5rem;
  }
}
.cardActions {
  padding: 1.5rem;
}
.providerList {
  list-style-type: none;

  li:first-child {
    margin-bottom: 0.625rem;
  }

  li:last-child {
    margin-top: 0.625rem;
  }
}
