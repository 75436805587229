.wrapper {
  border-bottom: 1px solid var(--color-lynx-white);

  &:last-child {
    border: none;
  }

  .textArea {
    height: 116px;
  }

  .ladder {
    display: flex;
  }
}

.wrapperTitle {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.numeric {
  div {
    width: 100%;
    margin-bottom: 0;
  }
}

.dropDown {
  margin-bottom: 16px;
}

.errorText {
  margin-top: 8px;
}

.readonlyRange {
  font-size: 1rem;
}
