@use "sass:math";

.wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0
}

.iframe {
  width: 100%;
  height: 100%;
}

.closeHack {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: var(--color-white);
  text-align: center;
  height: 26px;
  width: 109px;
  cursor: pointer;
  z-index: 10000;
  text-transform: uppercase;
  line-height: 26px;
}
