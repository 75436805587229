
.drDescription {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.drRemove {
  cursor: pointer;
}

.emptyList {
  text-align: center;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 0 auto;
  font-size: 0.813rem;
  color: var(--color-dark-gray);
}
