.header {
  margin-bottom: 40px;
}

.control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: baseline;

  .addWeightButton {
    height: 40px;;
  }
}
