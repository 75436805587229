.switchContainer {
  display: flex;
  gap: 2rem;
  margin: 32px 0 16px 0;
  z-index: 0;
}

.switchContainerShifted {
  margin-left: 2rem;
}

.switchOption {
  position: relative;
  font-size: 1rem;
  color: var(--color-dark-gray);
  cursor: pointer;
}

.switchOptionActive {
  color: var(--color-black);
  font-weight: 500;

  &::after {
    content: '';
    position: absolute;
    border-bottom: solid 2px var(--color-black);
    bottom: calc(-1rem - 1px);
    left: 0;
    width: 100%;
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: var(--color-lynx-white);
  margin-bottom: 24px;
}
