@use 'src/styles/typography' as t;

@mixin resetDefaultIndentations {
  * {
    margin: 0;
    padding: 0;
    border: 0;
  }
}

@mixin setFontRobotoByDefault {
  *, [class*='input'], [class*='text-area'] {
    @include t.Roboto;
  }
}
