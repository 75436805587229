.card {
  width: 676px;
  max-width: 80vw;
}

.cancel, .complete, .disable {
  font-size: 13px;
  font-weight: 500;
}

.complete {
  width: 147px;
  font-weight: bold;
}

.disable, .disable:hover {
  background-color: var(--color-very-light-grey);
  color: var(--color-dark-gray);
}

.cardHeader {
  padding: 24px 24px 0;

  p {
    margin-top: 0;
  }
}

.cardContent {
  padding: 8px 24px 14px;

  .uploadFile {
    margin-bottom: 24px;
  }

  .label {
    font-size: 13px;
    color: var(--color-dark-gray);
    margin-bottom: 8px;
  }

  .error {
    margin-top: 8px;
  }

  .documentType {
    margin-bottom: 12px;

    .singleDropdown {
      input {
        height: 40px !important;
      }
    }
  }

  .textOther {
    margin-top: 12px !important;
  }

  .date {
    margin-top: 24px;
    width: 330px;

    input {
      width: 330px;
      height: 40px;
      box-sizing: border-box;
    }

    svg {
      top: 0.7rem;
    }
  }

  .note {
    margin-top: 24px;
    margin-bottom: 25px;

    textarea {
      height: 150px;
    }
  }

  .checkboxBlockTitle {
    font-size: 18px;
    font-weight: 500;
    margin: 32px 0;
  }

  .multiCheckbox {
    margin-bottom: 33px;
  }

  .singleCheckbox {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 33px;

    label {
      gap: 8px;
      align-items: normal;
    }

    label::before {
      border: 1px solid var(--color-dark-gray);
    }
  }
}

.cardActions {
  padding: 24px;
}

