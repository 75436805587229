@mixin active {
  background-color: var(--color-accent) !important;

  * {
    color: colot(--var-black) !important;
  }
}

.root {
  width: 100%;
  height: 4em;
  padding-top: 0.3em;
  box-sizing: border-box;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 1440px) {
    width: 100%;
    margin-right: 10px;

    > div {
      padding: 0.8rem 0 0.5rem;
    }
  }
}

.hoverable {
  &:hover {
    cursor: pointer;

    @include active;
  }
}

.active {
  @include active;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.monthDay {
  font-size: 24px;
  font-weight: 600;
}

.weekDay {
  position: relative;
  top: 20px;
  color: var(--color-dark-gray);
  font-size: 13px;
  font-weight: normal;
  text-transform: uppercase;
}

.activeDay {
  color: var(--color-black) !important;
}
