.active {
  &,
  &:hover {
    background-color: var(--color-accent) !important;
    color: var(--color-black) !important;
    border-radius: 100% !important;
  }
}

.header {
  width: auto !important;

  &.wideHeader {
    width: 445px !important;
  }

  &.monthHeader {
    width: 300px !important;
  }
}

.label {
  font-size: 13px;
  color: var(--color-dark-gray);
  margin-bottom: 0.5rem;
}

.preloader > div > div {
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--color-white);
    opacity: 0.85;
    z-index: 9;
    border-radius: 0.3rem;
  }

  &:after {
    content: '';
    width: 32px;
    height: 32px;
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 16px);
    border-radius: 50%;
    z-index: 99;
    border: 3px solid;
    animation: loading 1.2s cubic-bezier(0.12, 0.52, 0.48, 0.08) infinite;
    border-color: var(--color-accent) var(--color-accent) var(--color-accent) transparent;
  }
}

.calendarIcon {
  position: absolute;
  $size: 1rem;
  height: $size;
  width: $size;

  &.calendarIconSmall {
    right: 0;
  }
  &.calendarIconDefault {
    right: 1rem;
    top: 1rem;
  }
  &.calendarIconWide {
    right: 1rem;
    top: 1.5rem;
  }

  &.calendarIconLabResults {
    left: calc(100% - 0.6rem);
    top: 0.8rem;
  }

  &.calendarIconMedications {
    right: 0;
    top: 0.8rem;
  }
}

.inputWrapper {
  display: inline-block;
  position: relative;

  &.inputWrapperLabResults {
    width: calc(100% - 1.5rem);
  }

  &.inputWrapperMedications {
    width: calc(100% - 1rem);
  }
}

.input {
  margin: 0;

  &:focus {
    border: none;
    outline: solid 1px var(--color-black);
  }

  &.typeDefault {
    font-size: 1rem;
    width: calc(225px - 1rem);
    height: calc(3rem - 2px);
    border-radius: 8px;
    border: solid 1px var(--color-light-gray);
    padding-left: 1rem;
  }

  &.typeSmall {
    width: 100%;
  }

  &.typeWide {
    font-size: 1rem;
    width: calc(100% - 1rem);
    height: 60px;
    border-radius: 8px;
    border: solid 1px var(--color-light-gray);
    padding-left: 1rem;
  }

  &.typeLabResults {
    font-size: 1rem;
    width: calc(100% - 2px);
    height: calc(3rem - 2px);
    border-radius: 8px;
    border: solid 1px var(--color-light-gray);
    padding-left: 0.88rem;
    padding-right: 0.62rem !important;
  }

  &.typeMedications {
    font-size: 1rem;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: solid 1px var(--color-light-gray);
    padding-left: 12px;
  }

  &.inputDisabled {
    background-color: var(--color-very-light-grey);
    color: var(--color-light-gray);
  }

  &.isNotValid {
    border-color: var(--color-danger);
  }

  &.isNotValidBackground {
    background-color: var(--color-danger-light);
  }
}

.ariaLabel {
  display: none;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
