.formElements {
  padding: 20px;
}

.discontinuedDate {
  width: 50%;
}

.error {
  margin-top: 10px;

  div {
    font-size: 13px;

    svg {
      margin-right: 4px;
    }
  }
}
