.root {
  width: max-content;
}

.tooltip {
  border-radius: 8px !important;
  background-color: var(--color-darkest-gray) !important;
  color: var(--color-white) !important;
  font-size: 15px !important;
  padding: 8px 12px !important;
  z-index: 10000 !important;
}
