.card {
  position: relative;
  width: 658px;
  z-index: 1;
}

.message {
  width: 519px;
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: left;
}
