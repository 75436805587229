.root {
  display: grid;
  grid-row-gap: 1rem;

  input {
    border-color: var(--color-light-gray);
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  background-color: var(--color-gray);
}

.submit {
  font-weight: 500;
  width: 11rem;
  height: 2.7rem;
  margin: 32px;
}
