.root {
  display: grid;
  justify-items: center;
  grid-template-rows: min-content 1fr;
  grid-row-gap: 4rem;
  width: 850px;
  margin: 0 auto;
}

.header {
  padding: 2rem 0;
}

.logo {
  height: 25px;
}

.main {
  width: 100%;
}

.footer {
  max-width: 444px;
  padding: 1rem 0;
}
