.root {
  display: flex;
  flex-direction: column;

  position: relative;

  .label {
    padding: 0 0 7px 15px;
    line-height: 1.23;
    color: var(--color-dark-gray);

    &.small, &.smallLabel {
      padding: 0 0 4px 10px;
    }

    &.smallLabel {
      font-size: 13px;
    }
  }

  .input {
    height: 40px;
    border-radius: 8px;
    border: solid 1px var(--color-light-gray);
    outline: none;
    padding: 0 15px;
    font-size: 16px;
    background-color: var(--color-white);
    box-sizing: border-box;

    &:focus {
      border-color: var(--color-black);
    }

    &.disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.5;
      background-color: var(--color-very-light-grey);
      caret-color: transparent;
    }

    &.small, &.smallLabel, &.default {
      height: 30px;
    }

    &.default {
      border: solid 1px var(--color-light-gray);
    }

    &.invalid {
      border-color: var(--color-danger);
      background-color: var(--color-danger-light);

      &:focus {
        border-color: var(--color-danger);
      }
    }
  }

  &.labelInInput {
    border: solid 1px var(--color-light-gray);
    border-radius: 8px;
    padding: 12px 0;
    height: 34px;

    .label {
      font-size: 13px;
      padding: 0 12px 3px;
      width: max-content;
    }

    .input {
      border: none !important;
      height: 16px;
      padding: 0 12px;

      &.noLabel {
        margin-top: 8px;
      }
    }


    &.invalid {
      border-color: var(--color-danger);
      background-color: var(--color-danger-light);

      &:focus {
        border-color: var(--color-danger);
      }

      .label {
        color: var(--color-danger);
      }
    }
  }

  & .closeButton {
    position: absolute;
    top: 13px;
    right: 8px;

    background: inherit;

    &:hover {
      cursor: pointer;
    }
  }
}
