.root {
  display: flex;
}

.unitsDropdown {
  font-size: 1rem;
  width: 5.9rem !important;

  & input {
    border-radius: 0 8px 8px 0 !important;
    border: solid 1px var(--color-light-gray);
    padding-left: 0.7rem;

    &:focus {
      border: solid 1px var(--color-light-gray);
      border-radius: 0 8px 8px 0 !important;
    }
  }

  :global(.single-dropdown__dropdown-container) {
    border: none;
    border-radius: 8px;
    box-shadow: 0 24px 53px 0 rgba(0, 0, 0, 0.27);
  }

  :global(.single-dropdown__option--selected) {
    background-color: var(--color-grey-secondary);
    color: var(--color-dark-gray);
  }
}

.expressionDropdown {
  font-size: 1rem;
  width: 5rem;

  & input {
    border-radius: 8px;
    border: solid 1px var(--color-light-gray);

    &:focus {
      border: solid 1px var(--color-light-gray);
    }
  }

  margin-right: 0.5rem;

  :global(.single-dropdown__dropdown-container) {
    border: none;
    border-radius: 8px;
    box-shadow: 0 24px 53px 0 rgba(0, 0, 0, 0.27);
  }

  :global(.single-dropdown__option--selected) {
    background-color: var(--color-grey-secondary);
    color: var(--color-dark-gray);
  }
}

.numeric {
  width: 8.8rem;
  font-size: 1rem;

  & > input {
    width: 8.8rem !important;
    border-radius: 8px 0 0 8px !important;
    border: solid 1px var(--color-light-gray);

    &:focus {
      border: solid 1px var(--color-light-gray);
    }
  }
}
