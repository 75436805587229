@mixin Lora {
  font-family: 'Lora', serif;
}

@mixin Roboto {
  font-family: Roboto, sans-serif;
}

@mixin RobotoMono {
  font-family: RobotoMono, monospace;
}

@mixin text-overflow($size, $lines: 1) {
  font-size: $size;
  line-height: $size * $lines;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
}
