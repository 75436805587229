.root {
  background-color: var(--color-white);
  display: flex;
  align-items: center;
}

.root svg {
  margin-right: .50rem;
}

.fontSizeDefault {
  font-size: 16px;
}

.fontSizeSmall {
  font-size: 13px;
}

.messageColorDefault {
  color: var(--color-dark-gray);
}

.messageColorRed {
  color: var(--color-danger);
}

.messageColorGreen {
  color: var(--color-success);
}
