.root {
  width: 50%;

  div {
    display: flex;
    width: 100%;

    input {
      width: 100%;
      margin-bottom: 5px;
    }
  }
}
