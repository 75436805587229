@use "src/styles/variables" as v;
@use "src/styles/mixins" as m;

.asideWrap {
  position: sticky;
  right: 0;
  height: 100%;
  width: v.$right-aside-width;
  padding: 40px 40px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  @include m.scrollbar();

  @media (max-width: v.$hide-asides-width) {
    height: 95vh;
  }
}

.open {
  position: absolute;
  right: 0;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 24px;
}

.trellusTeamContent {
  margin-bottom: 40px;
}

.separator {
  height: 4px;
  background-color: white;
  width: 390px;
  position: relative;
  left: -40px;
}

.avatar {
  background-size: cover;
}

.trellusTeamItem {
  display: flex;
  margin-top: 11px;
  padding-bottom: 11px;
  align-items: center;
  border-bottom: 1px solid var(--color-silver);

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.trellusTeamItemInfo {
  margin: 0 16px;
  width: 200px;
}

.trellusTeamItemMessage {
  height: 32px;
  width: 32px;
  border: 2px solid var(--color-accent);
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;
}

.trellusTeamItemName {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.trellusTeamItemName {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.trellusTeamItemTitle {
  font-size: 13px;
  color: var(--color-dark-gray);
}

.trellusTeamItemMessageIcon {
  height: 16px;
  width: 16px;
  position: relative;
  left: 2px;
}

.dateSelector {
  margin: 30px 0 40px -20px;
}

.dateSelectorCustomHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 15px 8px;
}

.dateSelectorActions {
  display: flex;
}

.dateSelectorAction {
  font-size: 24px;
  height: 16px;
  position: relative;
  top: -6px;
  width: 16px;
  margin-left: 6px;
  cursor: pointer;
  user-select: none;
}

.dateSelectorCustomHeaderMonth {
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
}

.emergency {
  margin-bottom: 40px;
}

.emergencyButton {
  width: 100%;
  height: 40px;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 1.16px;
  background-color: var(--color-white);
  border: solid 1px var(--color-black);
  margin-bottom: 0.5rem;
  color: var(--color-black);

  &:hover {
    background-color: var(--color-very-light-grey);
  }
}

.emergencyButton:last-child {
  margin-bottom: 0;
}
