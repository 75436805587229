@mixin gridColumns($columns-count: 4, $column-size: 1fr) {
  grid-template-columns: repeat($columns-count, $column-size);
}

.list {
  display: grid;
  grid-row-gap: 0.5rem;

  li {
    &::marker {
      color: var(--color-accent);
    }
  }
}

.noStyle {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.title {
  font-weight: 500;
  font-size: 1rem;
  margin: 0.7rem 0;
}

.sixColumns {
  @include gridColumns(6, 1fr);
  grid-row-gap: 0.25rem;
  grid-column-gap: 0.25rem;
}

.titleHolder {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.collapsibleContent {
  padding: 0 6px;
  overflow: hidden;
  transition: opacity 0.4s ease;
}

.collapsed {
  max-height: 0;
  opacity: 0;
}

.expanded {
  opacity: 1;
}
