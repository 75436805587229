$border: 6px solid var(--color-accent);

.root {
  position: relative;
  background-color: white;
  border-radius: .5rem;
  border: 1px solid var(--color-background);
}

.borderLess {
  border: none;
}

.borderTop {
  border-top: $border;
}

.borderLeft {
  border-left: $border;
}

.borderRight {
  border-right: $border;
}

.borderBottom {
  border-bottom: $border;
}

.borderLessonColor {
  border-top-color: var(--color-border-lessons);
}

.borderTaskColor {
  border-top-color: var(--color-border-tasks);
}

.chipped {
  position: relative;
}

.chip {
  display: inline-block;

  padding: .7rem 1rem;
  box-sizing: border-box;

  background-color: var(--color-background);
  filter: contrast(0.9);

  $border-radius: 1.1rem;
  border-top-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.chipText {
  color: var(--color-accent);
}

.colorDanger {
  background-color: var(--color-danger);
  color: var(--color-secondary);
}

.colorSuccess {
  background-color: var(--color-success);
  color: var(--color-background);
}

.colorSecondary {
  background-color: var(--color-white-smoke);
  color: var(--color-dark-gray);
}

.colorGreySecondary {
  background-color: var(--color-grey-secondary);
}

.colorBackground {
  background-color: var(--color-background);
  color: var(--color-secondary);
}

.colorLightYellow {
  background-color: var(--color-light-yellow);
}
