.root {
  display: flex;
  flex-direction: column;

  .label {
    padding: 0 0 7px 15px;
    line-height: 1.23;
    color: var(--color-dark-gray);

    &.small {
      padding: 0 0 4px 10px;
    }
  }

  .input {
    border-radius: 8px;
    border: solid 1px var(--color-light-gray);
    outline: none;
    padding: 0 15px;
    background-color: var(--color-white);
    box-sizing: border-box;
    height: 40px;
    font-size: 16px;

    &.default {
      border: solid 1px var(--color-light-gray);
    }

    &:focus {
      border-color: var(--color-black);
    }

    &.invalid {
      border-color: var(--color-danger);
      background-color: var(--color-danger-light);

      &:focus {
        border-color: var(--color-danger);
      }
    }
  }

  .errorField {
    margin-top: 8px;
  }

  &.labelInInput {
    border: solid 1px var(--color-light-gray);
    border-radius: 8px;
    padding: 12px 0;
    height: 34px;

    .label {
      font-size: 13px;
      padding: 0 12px 3px;
      width: max-content;
    }

    .input {
      border: none !important;
      height: 16px;
      padding: 0 12px;

      &.noLabel {
        margin-top: 8px;
      }
    }


    &.invalid {
      border-color: var(--color-danger);
      background-color: var(--color-danger-light);

      &:focus {
        border-color: var(--color-danger);
      }

      .label {
        color: var(--color-danger);
      }
    }
  }
}
