.root {
  div {
    display: inline-block;
    width: 100%;

    input {
      margin-bottom: 5px;
      height: 3rem;
      width: 100%;

      border-radius: 8px !important;
      border: solid 1px var(--color-light-gray);
      &:focus {
        border: solid 1px var(--color-light-gray);
        border-radius: 8px !important;
      }
    }
  }
}
