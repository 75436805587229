.root {
  width: 400px;

  .content {
    & > div {
      margin-bottom: 15px;
    }

    .unitDropdown {
      input {
        height: 40px;
      }
    }

    .inputNumber {
      width: 100%;

      input {
        width: 100%;
        margin-bottom: 0;
      }
    }

    .errorText {
      margin-top: 8px;

      & > div {
        align-items: flex-start;
      }
    }
  }
}
