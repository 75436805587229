.errorText {
  display: grid;
  align-items: center;
  grid-template-columns: min-content auto;
  color: var(--color-danger);
  font-size: .75rem;
  margin-bottom: 0.5rem;

  span {
    display: inline-block;
    margin-left: 5px;
  }
}
