@use "src/styles/mixins" as m;

.root {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-night-rider-translucent);
  
  @include m.scrollbar();

  .wrapper {
    margin: auto 0;
    padding: 40px 0;
  }

  @media (max-width: 1299px) {
    .wrapper {
      padding: 80px 0;
    }
  }
}

.body {
  max-height: 100vh;
  overflow-y: hidden;
}
