@use "./variables" as v;

@mixin media-max($size) {
  @media (max-width: $size) {
    @content;
  }
}

@mixin media-min($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin media-exactly($min-size, $max-size) {
  @media (min-width: $min-size) and (max-width: $max-size) {
    @content;
  }
}

@mixin lg() {
  @include media-min(v.$screen-lg-min) {
    @content;
  }
}

@mixin md() {
  @include media-min(v.$screen-md-min) {
    @content;
  }
}

@mixin md-only() {
  @include media-exactly(v.$screen-md-min, v.$screen-md-max) {
    @content;
  }
}

@mixin md-max() {
  @include media-max(v.$screen-md-max) {
    @content;
  }
}

@mixin sm() {
  @include media-min(v.$screen-sm-min) {
    @content;
  }
}

@mixin sm-only() {
  @include media-exactly(v.$screen-sm-min, v.$screen-sm-max) {
    @content;
  }
}

@mixin sm-max() {
  @include media-max(v.$screen-sm-max) {
    @content;
  }
}

@mixin xs() {
  @include media-min(0px) {
    @content;
  }
}

@mixin xs-max() {
  @include media-max(v.$screen-xs-max) {
    @content;
  }
}
