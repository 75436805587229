.inputPassword {
  display: flex;
  flex-direction: column;
}

.label {
  padding: 0 0 7px 15px;
  line-height: 1.23;
  color: #d4e6e8;
  font-size: 14px;
}

.inputContainer {
  position: relative;
}

.input {
  height: 60px;
  border-radius: 10px;
  border: 1px solid #eeedea;
  outline: none;
  padding: 0 15px;
  background-color: var(--color-white);
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;

  margin-bottom: 1rem;

  &:focus {
    border-color: var(--color-link)
  }
}

.inputInvalid {
  border-color: var(--color-danger);

  &:focus {
    border-color: var(--color-danger);
  }
}

.showPassword {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: 0.9rem;
  font-size: 13px;
  color: var(--color-dark-gray);
}
