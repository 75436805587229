.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  gap: 20px;

  .button {
    border-radius: 0.5rem;
    height: 40px;
  }
}
