.followUp {
  margin-top: 32px;
}

.detailLabel {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-black);
}

.detailValue {
  margin-top: 12px;
  font-size: 16px;
  color: var(--color-black);
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.followUpValue {
  display: flex;
  gap: 12px;
  flex-direction: column;

  .followUpSubValue {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.followUpIcon {
  height: 16px;
}
