@use "src/styles/mixins" as m;

.root {
  display: flex;
  flex-direction: column;

  label {
    padding: 0 0 7px 15px;
    line-height: 1.23;
    color: var(--color-dark-gray);

    &.smallLabel {
      font-size: 13px;
    }
  }

  .textArea {
    resize: none;
    height: 100px;
    border-radius: 10px;
    border: solid 1px var(--color-light-gray);
    outline: none;
    padding: 15px;
    background-color: var(--color-white);
    box-sizing: border-box;
    font-size: 16px;
    @include m.scrollbar();

    &.form {
      border-radius: 20px 20px 0 0;
      border: solid 1px var(--color-dark-gray);
    }

    &.cardMediumScreen, &.cardTablet {
      height: 100%;
    }

    &:focus {
      border-color: var(--color-black);
    }

    &.invalid {
      border-color: var(--color-danger);
      background-color: var(--color-danger-light);

      &:focus {
        border-color: var(--color-danger);
      }
    }

    &.disabled {
      background-color: var(--color-very-light-grey);

      &:focus {
        border-color: var(--color-light-gray);
      }
    }
  }
}

.error {
  background-color: var(--color-danger-light);
  border-color: var(--color-danger);
}

.errorMessage {
  margin-top: 0.5rem;
}
