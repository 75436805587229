.emptyListCard {
  background: var(--color-white-smoke);
}

.emptyListContentWrapper {
  padding: 2rem;
}

.emptyListContent {
  display: grid;
  grid-row-gap: 0.75rem;
  justify-items: center;
  align-items: center;
}

.emptyListMessage {
  font-size: 1rem;
  font-weight: 300;
  color: var(--color-dark-gray);
}
