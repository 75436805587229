.root {
  width: 50%;
  margin: 0 auto;
}

.message {
  font-size: 1rem;
}

.checkbox {
  width: fit-content;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
}
