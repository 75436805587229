.root {
  position: relative;
}

.dropdown {
  position: absolute;
  z-index: 2;
}

.fullWidth {
  width: 100%;
}

.top {
  top: -25px;
}

.reverseTop {
  top: 25px;
}

.medicalHistory {
  top: 54px;
  width: 100%;
}

.searchDropdown {
  top: calc(3rem + 4px);
}

.datepicker {
  top: 2rem;
}

.bottom {
  bottom: -50px;
}

.right {
  right: 0;
}

.left {
  left: 0;
}
