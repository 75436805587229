.root {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.removeMargin {
    margin: 0;
  }
}

.text {
  font-size: 13px;
  color: var(--color-dark-gray);
  margin-top: 16px;
}
