.banners {
  display: flex;
  height: max-content;
  & > div {
    width: 100%;
    height: max-content;
    &:not(:first-child) {
      margin-left: -95%;
   }
  }
}

@for $i from 1 through 3 {
  .banners > div:nth-child(#{$i}) {
    transform: translateY(#{($i - 1) * 20}#{"px"});
  }
}

.container {
  background: #FFF9EE;
  border-top: 6px solid var(--color-accent);
}

.cardContent {
  border-radius: 8px;
}

.warningIcon {
  margin-top: 0;
}

.title {
  display: flex;
  justify-content: center;
  max-width: 93%;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
}

.text {
  max-width: 93% !important;
  font-size: 16px;
  word-break: break-word;
  width: 100%;
  text-align: center;
}

.buttonLayout {
  max-width: 93% !important;

  button {
    margin: auto;
  }
}

.expandText {
  color: var(--color-black);
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  max-width: 93%;
}
