.boldSection {
  padding-top: 23px;
  font-size: 1.5rem;
  font-weight: bold;
}

.symptomsCardContainer {
  display: grid;
  grid-row-gap: .7rem;
}

.symptomsCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.symptomsCardTitle {
  font-size: .9rem;
  width: 40%;
  font-weight: 500;
}

.symptomsCardContent {
  flex-grow: 4;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.submit {
  width: 11.25rem;
  height: 2.7rem;
  margin: 2rem;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.25px;
}

.boldSpan {
  font-weight: bold;
}

.titleSection {
  padding-top: 1.5rem;
  padding-left: 0.8rem;

  p:first-child {
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
  }
}
