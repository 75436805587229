.root {
  padding: 1.2rem;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  border-radius: .55rem;
  box-shadow: 0 24px 53px 0 rgba(0, 0, 0, 0.27);
  background-color: var(--color-white);
}

.root li {
  margin-bottom: .55rem;
}

.root li:last-child {
  margin-bottom: 0;
}

.root::before {
  content: '';
  position: absolute;
  left: 50%;
  top: -30px;
  margin-left: -20px;
  border: 20px solid transparent;
  border-bottom: 20px solid var(--color-white);
}
