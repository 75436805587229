.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;

  &:not(:last-of-type){
    border-bottom: 1px solid var(--color-lynx-white);
  }
}
