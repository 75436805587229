.root {
  display: grid;
  grid-row-gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.label {
  font-size: 13px;
  color: var(--color-dark-gray);
}
