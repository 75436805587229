.container {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white-smoke);
  border-radius: 8px;
  padding: 24px;
  font-size: 16px;
}
