.input {
  font-size: 1rem;
  margin-top: 8px;
  margin-left: 20px;
  width: 100%;
  box-sizing: border-box;

  & > input {
    width: calc(100% - 20px) !important;
    border-radius: 8px;
    margin-bottom: 0 !important;
    height: 40px !important;

    &:focus {
      border: solid 1px var(--color-black) !important;
    }
  }

  &.inputUnderDropdown {
    margin-left: 0;

    & > input {
      width: 100% !important;
    }
  }
}

.optionWrapper {
  font-size: 34px !important;
}

.reasonsDropdown {
  input {
    height: 40px;
  }
}

.error {
  margin-top: 10px;
}


.fullWidth{
  width:100%;
}

.halfWidth{
  width: calc(50% - 10px);
}
