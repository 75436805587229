.root {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 372px;
  min-height: 414px;
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 24px 53px 0 rgba(0, 0, 0, 0.27);

  & input, & input:focus {
    height: 2.5rem !important;
    font-size: 1rem;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  :global(.single-dropdown__dropdown-container) {
    border: none;
    border-radius: 8px;
    box-shadow: 0 24px 53px 0 rgba(0, 0, 0, 0.27);
  }

  :global(.single-dropdown__option--selected) {
    background-color: var(--color-grey-secondary);
    color: var(--color-dark-gray);
  }

  :global(.single-dropdown__option-text) {
    font-size: 1rem;
  }
}

.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.header {
  font-size: 20px;
  font-weight: 500;
}

.closeIcon {
  background: inherit;
  cursor: pointer;
  svg {
    $size: 11px;
    width: $size;
    height: $size;
  }
}

.button {
  font-size: 13px;
  font-weight: bold;
  width: 11.25rem;
  height: 2.5rem;
  margin: auto auto 0 auto;
  letter-spacing: 1.16px;
}
