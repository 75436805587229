.root {
  display: flex;
  justify-content: space-between;
  margin: 6px 0;
}

.selector {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .toggle {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    margin: 0 15px;

    &.active svg {
      transform: rotate(180deg);
    }

    svg {
      margin-left: 8px;
      width: 16px;
      height: 16px;
    }
  }

  .select {
    padding: 4px 0;
    border-radius: 8px;
    box-shadow: 0 24px 53px 0 rgba(0, 0, 0, 0.27);
    background-color: var(--color-white);
    width: 100%;

    .options {
      overflow-y: auto;
      height: 232px;

      .option {
        cursor: pointer;
        font-size: 18px;
        padding: 8px 30px 8px 16px;
        text-align: left;
        white-space: pre;
        min-width: 87px;
        box-sizing: border-box;

        &.active {
          background: var(--color-white-smoke);
        }

        &:hover {
          background: var(--color-white-smoke);
        }
      }

      &::-webkit-scrollbar {
        width: 10px;
        background-color: var(--color-white);
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: var(--color-gainsboro);
        border: 3px solid var(--color-white);

      }
    }
  }
}


