.card {
  position: relative;
  width: 503px;
  z-index: 1;
}

.text {
  font-size: 16px;
  line-height: 1.63;
  margin-bottom: 23px;

  &.centerText {
    text-align: center;
  }
}

.buttonsWrapper {
  & > * {
    &:not(:first-child) {
      margin-left: 24px;
    }
  }
}
