.modalContent {
  background-color: var(--color-white);
  padding: 40px 30px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 550px;
  min-width: 400px;
  max-height: 80vh;
  border: 2px solid var(--color-primary-yellow);
}

.modalHeader {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalHeader h2 {
  color: var(--color-burgundy);
}

.modalFooter {
  z-index: 1;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.requiredSign {
  color: var(--color-pink);
}

.fullWidth {
  flex-basis: 100%;
}

.button {
  width: 120px;
}

.darkTextColor {
  color: var(--color-black);
}
