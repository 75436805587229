.dates {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(227px, max-content));
  grid-column-gap: 16px;
  grid-row-gap: 5px;
}

.dateContainer {
  width: calc(225px - 1rem);
}

.doseTitle {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.inputContainer {
  position: relative;
  cursor: pointer;
}

.input {
  font-size: 1rem;
  width: calc(225px - 1rem);
  height: calc(3rem - 2px);
  border-radius: 8px;
  border: solid 1px var(--color-light-gray);
  padding-left: 1rem;

  &:focus {
    border: solid 1px var(--color-black);
  }
}

.icon {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
