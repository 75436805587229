@use 'src/styles/variables' as v;

.contactList {
  list-style: none;
  padding: 0;
}

.contactListCaption {
  font-size: 13px;
  font-weight: 600;
  line-height: 1.62;
  margin-bottom: 12px;
}

.contactListItem {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.contactListLink {
  font-size: 13px;
  color: var(--color-grey-link);
}
