.root {
  position: relative;
}

.input {
  padding: .5rem 1rem;
  border-radius: 1.5rem;
  font-size: 1rem;
  border: 1px solid #e4e4e4;
  width: 100%;
  box-sizing: border-box;

  &::placeholder {
    color: var(--color-dark-gray);
  }

  &:focus {
    outline: none;
    border: 1px solid black;
  }
}

.icon {
  position: absolute;
  top: .75rem;
  right: .75rem;
}
