.section {
  display: grid;
  grid-row-gap: .5rem;
}

.sectionHeader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  padding: 0 0 20px 0;
}
