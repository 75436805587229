.dropdown {
  label {
    color: var(--color-black);
  }
}

.inputField {
  input {
    height: 3rem !important;
  }
  input:disabled {
    opacity: 0.5;
    background-color: var(--color-very-light-grey);
  }
}

.root {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


.searchFormTitle {
  text-decoration: none;
  text-align: center;
  color: var(--color-grey-link);
}

.errorField {
  margin-top: 0.5rem;
}
