.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  font-size: 13px;
  color: var(--color-dark-gray);
  margin-bottom: 8px;
}

.errorLabel {
  color: var(--color-danger);
}
