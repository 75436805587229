.button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 20px;
  color: var(--color-white);
  font-weight: 500;
  text-align: center;
  background: initial;

  &--common {
    background-color: var(--color-link);
    font-size: 20px;
    box-shadow: 0 2px 15px 0 rgba(7, 0, 200, 0.2);
  }

  &--small {
    height: 30px;
    background-color: var(--color-link);
    font-size: 14px;
    box-shadow: 0 2px 15px 0 rgba(7, 0, 200, 0.2);
  }

  &--cancel-small {
    height: 30px;
    background-color: var(--color-white);
    color: var(--color-link);
    font-size: 14px;
    box-sizing: border-box;
    border: 1px solid var(--color-link);
    box-shadow: 0 2px 15px 0 rgba(7, 0, 200, 0.2);
  }

  &--cancel-common {
    background-color: var(--color-white);
    color: var(--color-link);
    font-size: 20px;
    box-sizing: border-box;
    border: 1px solid var(--color-link);
    box-shadow: 0 2px 15px 0 rgba(7, 0, 200, 0.2);
  }


  &--link {
    height: 40px;
    font-size: 14px;
    color: var(--color-link);
    text-decoration: underline;
  }

  &--disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  &--block-action {
    background-color: var(--color-alabaster);
    color: var(--color-link);
    font-size: 16px;
    height: 70px;
    border-radius: 0;

    .button__icon {
      margin-right: 12px;
    }
  }

  &--block-action-light {
    background-color: transparent;
    color: var(--color-link);
    font-size: 16px;
    font-weight: normal;
    border-radius: 0;

    .button__icon {
      margin-right: 12px;
    }
  }

  &--outlined-small {
    height: 30px;
    background-color: var(--color-white);
    color: var(--color-link);
    font-size: 14px;
    box-sizing: border-box;
    border: 2px solid var(--color-link);
    box-shadow: 0 2px 15px 0 rgba(7, 0, 200, 0.2);
  }

  &--secondary-small {
    height: 30px;
    background-color: var(--color-dark-gray);
    color: var(--color-link);
    font-size: 12px;
    font-weight: normal;
    box-sizing: border-box;
    border: none;
  }

  &--separate-icon {
    height: 30px;
    background-color: transparent;
    color: var(--color-link);
    font-size: 12px;
    font-weight: normal;
    box-sizing: border-box;
    border: none;
    display: flex;
    align-items: center;
  }

  &--icon {
    background-color: transparent;
    color: var(--color-link);
    box-sizing: border-box;
    border: none;
    display: flex;
    align-items: center;
    height: 30px;
    width: 30px;
  }

  &--full-icon {
    height: 100%;
    width: 100%;
  }

  &--dotted-small {
    height: 30px;
    font-size: 13px;
    color: var(--color-link);
    border: dotted 2px;
    font-weight: normal;
    box-sizing: border-box;
    background-color: var(--color-white);
  }

  &--banner {
    font-size: 15px;
  }

  &--dialog-header {
    padding: 0 15px;
    color: var(--color-white);
    background: var(--color-link);
    box-shadow: none;

    font-size: 14px;
    font-weight: 500;
    line-height: 2.14;
  }

  &--dialog-header-disabled {
    padding: 0 15px;
    color: var(--color-white);
    background: var(--disabled-banner-color);
    box-shadow: none;

    font-size: 14px;
    font-weight: 400;
    line-height: 2.14;

    opacity: 1;
  }

  &--icon-patient-info-mobile,
  &--icon-client-info-mobile,
  &--icon-patient-info-tablet,
  &--icon-client-info-tablet,
  &--icon-patient-info-medium-screen,
  &--icon-client-info-medium-screen {
    background-color: var(--color-white-smoke);
    border-radius: 50%;
  }

  &--icon-patient-info-mobile,
  &--icon-client-info-mobile {
    width: 35px;
    height: 35px;
  }

  &--icon-patient-info-tablet,
  &--icon-client-info-tablet,
  &--icon-patient-info-medium-screen,
  &--icon-client-info-medium-screen {
    width: 60px;
    height: 60px;
  }

  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;

    &--separate-icon {
      height: 30px;
      min-width: 30px;
      border-radius: 15px;
      background-color: var(--color-dark-gray);
    }

    &--icon {
      height: 30px;
      width: 30px;
    }
  }

  &__icon {
    &--secondary-small {
      height: 8.5px;
      width: 8.5px;
    }

    &--separate-icon {
      height: 14px;
      width: 14px;
    }

    &--icon-patient-info-mobile {
      width: 18px;
      height: 18px;
    }

    &--icon-patient-info-tablet,
    &--icon-patient-info-medium-screen {
      width: 30px;
      height: 30px;
    }

    &--icon-client-info-mobile {
      width: 35px;
      height: 35px;
    }

    &--icon-client-info-tablet {
      width: 60px;
      height: 60px;
    }
  }

  &__text {
    &--with-icon {
      margin-left: 8px;
    }

    &--separate-icon {
      font-size: 16px;
      margin-right: 10px;
    }
  }
}
