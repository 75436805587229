.content {
  border-radius: 20px;
  border: solid 2px var(--color-gray);
  min-height: 795px;
}

.medicationsHeader {
  margin-bottom: 35px;
}

.topContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 13px;
  background-color: var(--color-burgundy);
  color: var(--color-white);
  width: 195px;
  box-sizing: border-box;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  letter-spacing: 1.16px;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    background-color: var(--color-burgundy-light);
  }
}

.noneMedications {
  padding: 32px !important;

  .noneMedicationsText {
    font-size: 16px;
    color: var(--color-dark-gray);
    margin-bottom: 8px;
  }

  .noneMedicationsButton {
    font-size: 1rem;
    text-decoration: underline;
    background: none;
    cursor: pointer;
  }
}


.cardModal {
  width: 676px;
  max-width: 80vw;
}

.cardContentModal {
  padding: 0;
}

.cardContentModalInfo {
  padding: 20px;
}

.modalInfoText {
  font-size: 1rem;
  word-break: break-all;
}
