.table {
  border-collapse: collapse;
  width: 100%;
}

.tableHeader {
  font-size: .8em;
  text-align: left;
  font-weight: 500;
  padding: 1rem 2rem;
  background-color: var(--color-gray);
  color: var(--color-dark-gray);
  text-transform: uppercase;

  &:first-child, {
    border-radius: 0.5rem 0 0 0;
  }

  &:last-child, {
    border-radius: 0 0.5rem 0 0;
  }
}

.noResultsContainer {
  background-color: var(--color-white-smoke);
  border-radius: .5em;
  gap: 1em;
}

.noResultsMessage {
  color: var(--color-dark-gray);
  font-size: 1rem;
}
