.option {
  display: none;

  & + *::before {
    $size: 18px;
    content: "";
    display: inline-block;
    vertical-align: bottom;
    box-sizing: border-box;
    width: $size;
    height: $size;
    border-radius: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: gray;
  }

  &:checked + *::before {
    background: radial-gradient(circle, var(--color-accent) 0%, var(--color-accent) 40%, transparent 45%, transparent);
    border-color: var(--color-accent);
  }
}

.optionWrapper {
  display: grid;
  gap: 1rem;
  grid-template-columns: 30px 1fr 2fr 3fr;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid var(--color-very-light-grey);

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }

  &.readonly {
    cursor: auto;
  }

  .label {
    align-items: center;
    width: 100%;
    font-size: 1rem;
    cursor: pointer;

    &.readonly {
      content: "";
      display: inline-block;
      vertical-align: bottom;
      box-sizing: border-box;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      border: 1px solid var(--color-light-gray);
      margin-right: 8px;
      background: var(--color-very-light-grey);
      cursor: auto;

      &:before {
        content: "";
        display: flex;
        width: 18px;
        height: 18px;
        background: radial-gradient(circle, var(--color-light-gray) 0%, var(--color-light-gray) 40%, transparent 45%, transparent);
        border-color: var(--color-light-gray);
        position: relative;
        top: -1px;
        left: -1px;

      }
    }
  }

  .type {
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
  }

  .info {
    display: grid;
    gap: .3rem;

    .title {
      font-weight: 500;
      font-size: 16px;
    }

    .subTitle {
      font-size: 14px;
      color: var(--color-dark-gray);
    }
  }
}

.optionError {
  & + *::before {
    border-color: var(--color-danger);
    background-color: var(--color-danger-light);
  }
}




