@use "sass:math";
@use "src/styles/typography" as t;

.root {
  position: relative;
  display: grid;
  grid-template-areas:
          "avatar title date"
          "avatar type type"
          "avatar text text";
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto;
  grid-column-gap: .75rem;
  grid-row-gap: .25rem;
  align-items: start;
}

.rootWithoutText {
  grid-template-areas:
          "avatar title"
          "avatar type"
}

.avatar {
  grid-area: avatar;
}

.title {
  grid-area: title;
  font-weight: 500;

  @include t.text-overflow(16px)
}

.type {
  grid-area: type;

  @include t.text-overflow(16px, 1.2);
  font-weight: 400;
}

.text {
  grid-area: text;
  color: var(--color-dark-gray);

  @include t.text-overflow(16px);
  font-weight: 400;
}

.date {
  grid-area: date;
  text-align: right;
  color: var(--color-dark-gray);
}

$new-messages-span-size: 13px;

.newMessages {
  position: absolute;
  left: 30px;
  top: 0;
  background-color: white;
  border-radius: 100%;

  height: $new-messages-span-size;
  width: $new-messages-span-size;

  display: grid;
  align-items: center;
  justify-items: center;
}

.newMessagesSpan {
  background-color: var(--color-success);
  border-radius: 100%;

  $size: math.div($new-messages-span-size, 1.5);
  height: $size;
  width: $size;
}
