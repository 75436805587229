.error-list {
  &__error {
    color: var(--color-danger);
    font-size: 18px;

    &:before {
      content: "*";
      margin-right: 10px;
    }
  }
}
