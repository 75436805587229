.customHeader {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 280px;
    margin: 0 auto;
    background-color: #efecdf;
    padding: 11px;
    border-radius: 20px;
}

.back {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 7px solid #297ae6;
    cursor: pointer;
}

.next {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 7px solid #297ae6;
    cursor: pointer;
}

.date {
    font-size: 18px;
    font-weight: 600;
}