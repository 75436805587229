@use "src/styles/variables" as v;

.root {
  position: relative;
  display: grid;
  width: 100%;
  max-width: 100vw;
  height: 100%;

  grid-template-areas:
          "header"
          "main"
          "footer";
  grid-template-rows: min-content min-content min-content;
  justify-items: stretch;
  column-gap: 2em;
  row-gap: .5em;
}

.footer {
  grid-area: footer;
}

.header {
  grid-area: header;

  display: flex;
  justify-content: center;

  margin-top: 0.5rem;

  @media (max-width: v.$hide-asides-width) {
    z-index: 1000;
  }
}

.main {
  margin-top: 1rem;
  grid-area: main;
  margin-bottom: 2rem;
  border-left: 2rem solid white;
  border-right: 2rem solid white;
  background-color: var(--color-very-light-grey);
  border-radius: 100px 0;
  height: 820px;

  @media (max-width: 1299px) {
    height: auto;
  }

}
