.root {
  width: 506px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
}

.message {
  padding: 0 4.25rem;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0 0 1.5rem;
  text-align: center;
}

.button {
  height: 2.5rem;
  width: 187px;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.85;
  letter-spacing: 1.16px;
  padding: 0;
  margin-bottom: 1.5rem;
}
