.root {
  max-width: 600px;
  padding: 20px 40px;
  border: 2px solid var(--color-success);
  min-width: 350px;
  border-radius: 8px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);

  .message {
    color: var(--color-dark-gray);
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;

    .link {
      font-weight: 500;
      color: var(--color-dark-gray);
    }
  }

  .button {
    height: 2rem;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.85;
    letter-spacing: 1.16px;
    background-color: var(--color-accent);
    color: var(--color-black);
  }

  .title {
    text-align: center;
    display: flex;
    color: var(--color-greeting);
    flex-direction: column;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 400;
  }
}
