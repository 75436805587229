.root {
  position: relative;

  &.stringType {
    padding: 0 1.5rem;
  }
}

.input {
  -webkit-appearance: none;
  border: none;
  width: 100%;
  height: 4px;
  background-color: var(--color-dandelion);
  outline: none;
  cursor: pointer;

  &.rtl {
    direction: rtl;
  }

  &::-webkit-slider-thumb {
    position: relative;
    -webkit-appearance: none;
    $size: 24px;
    width: $size;
    height: $size;
    border-radius: 100%;
    background-color: var(--color-accent);
    border: 2px solid var(--color-white);
    cursor: pointer;
    z-index: 9;
  }

  &::-moz-range-thumb {
    position: relative;
    -moz-appearance: none;
    $size: 24px;
    width: $size;
    height: $size;
    border-radius: 100%;
    background-color: var(--color-accent);
    border: 2px solid var(--color-white);
    cursor: pointer;
    z-index: 2;
  }
}

.items {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;

  .item {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 0.9rem;
    width: 1px;
    text-align: center;
    font-size: 1rem;
    background: transparent;
  }
}

.slicedMark {
  position: absolute;
  top: -2rem;
  content: '';
  width: 0.25rem;
  height: 1rem;
  border-radius: 8px;
  background-color: var(--color-behr-goldfish);
  z-index: 1;
  cursor: pointer;
}

.currentProgress {
  position: relative;
  left: 0;
  top: -8px;
  height: 4px;
  width: 90px;
  background: linear-gradient(to right, var(--color-dark-orange), var(--color-accent));
}
