.encounterHeader {
  margin-bottom: 32px;
}

.topContent {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tooltip {
  max-width: 275px;
}

.actionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 13px;
  width: 167px;
  box-sizing: border-box;
  height: 40px;
  border-radius: 20px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: var(--color-burgundy);
  color: var(--color-white);
  cursor: pointer;
  pointer-events: auto;

  &:hover {
    background-color: var(--color-burgundy-light);
  }
}

.filter {
  display: flex;
  gap: 16px;
}

.gridHeader {
  display: grid;
  grid-template-columns: 20% 30% 20% 15% 15%;
  font-size: 13px;
  font-weight: 500;
  color: var(--color-dark-gray);
  border: solid 1px var(--color-lynx-white);
  padding: 14px 0 15px 32px;
  background-color: var(--color-gray);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.empty {
  padding: 33px 5px;
  border-radius: 8px;
  background-color: var(--color-white-smoke);
  font-size: 16px;
  color: var(--color-dark-gray);
  margin-top: 8px;
  text-align: center;
}
