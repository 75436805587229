.fields {
  display: grid;
}

.actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 2rem;
  margin-top: 2rem;
}

.submit {
  width: 11.25rem;
  height: 3rem;
  padding: 0;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 1.25px;
}
