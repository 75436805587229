@use "src/styles/mixins" as m;

.dropdownArea {
  position: relative;
  height: 3rem;
}

.dropdownLabel {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0 1.125rem 0 0.75rem;
  height: 3rem;
  width: 100%;
  background-color: var(--color-white);
  border-radius: 0.5rem;
  border: 1px solid var(--color-light-gray);
  font-size: 1rem;
  cursor: pointer;
  color: inherit;

  &TextArea {
    max-width: calc(100% - 1rem);
  }
}

.noDisplayName {
  justify-content: flex-end;
}

.dropdownLabelOpened {
  border: 1px solid var(--color-black);
}

.chevronUp {
  transform: rotate(-90deg);
}

.chevronDown {
  transform: rotate(90deg);
}

.input {
  width: 100%;
  font-size: 1rem;
  border-radius: 8px 8px 0 0;
  border: none;
  border-bottom: 1px solid var(--color-light-gray);
  padding: 1rem .75rem;
  box-sizing: border-box;
  color: var(--color-black);

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 1rem;
    color: var(--color-dark-gray);
  }

  &:not(:focus) + .dropdown {
    transition: visibility linear .25s;
    visibility: hidden;
  }
}

.optionsContainer {
  border-radius: 8px;
  background-color: var(--color-white);
  z-index: 1;
  box-shadow: 0 24px 53px 0 rgba(0, 0, 0, 0.27);
}

.option {
  display: flex;
  align-items: center;
  text-align: left;
  line-height: 1;
  font-size: 1rem;
  padding: 10px 12px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-grey-secondary);
  }
}

.optionsList {
  @include m.scrollbar();
  max-height: 9.5rem;
  overflow-y: auto;
}
