.root {
  display: grid;
  grid-row-gap: 1rem;
  margin-bottom: 16px;
}

.dateContainer {
  display: flex;
  align-items: center;
}

.closeIcon {
  $size: 13px;
  height: $size;
  width: $size;
  cursor: pointer;
  margin-left: 1rem;
}

.addButtonContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.addIcon {
  $size: 20px;
  height: $size;
  width: $size;
  margin-right: 0.5rem;
}

.buttonText {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.16px;
}

.inputContainer {
  position: relative;
  cursor: pointer;
}

.input {
  font-size: 1rem;
  width: calc(225px - 1rem);
  height: calc(3rem - 2px);
  border-radius: 8px;
  border: solid 1px var(--color-light-gray);
  padding-left: 1rem;

  &:focus {
    border: solid 1px var(--color-black);
  }
}

.icon {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.reactDatepickerPopper {
  z-index: 5;
}
