.root {
  max-width: 470px;
  flex-flow: row nowrap;
  grid-column-gap: 10px;
  margin: 0;
  line-height: 1;

  & svg {
    height: 22px;
    width: 22px;
    min-width: 22px;
    margin: 0;
  }
}

.link {
  text-decoration: underline;
  color: inherit;
}

.text {
  color: var(--color-black);
  font-size: 16px;
  line-height: 1.5;
  font-weight: normal;
}
