.description {
  width: calc(100% - 3rem);
  padding: 1rem;
  box-sizing: border-box;
  background-color: var(--color-grey-secondary);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--color-black);
}

.textButton {
  display: inline-block;
  margin-top: 8px;
  background: none;
  outline: none;
  color: var(--color-dark-gray);
  font-size: 13px;
  cursor: pointer;
}
