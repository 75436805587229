.root {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 50px;
  padding: 0 5px;
}
.on {
  color: var(--color-light-orange);
  text-shadow: -1px 0 var(--color-burgundy), 0 1px var(--color-burgundy), 1px 0 var(--color-burgundy),
    0 -1px var(--color-burgundy);
}
.off {
  color: #ccc;
}
