.root {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 18px 10px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--color-black);
  user-select: none;
}

.calendarArrowsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendarArrows {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
  }

  &:disabled {
    filter: opacity(35%);
  }
}

.calendarArrowsLeft {
  margin-left: 7px;
  height: 12px;
}

.calendarArrowsRight {
  margin-right: 7px;
  height: 12px;
}

.disabledArrow {
  visibility: hidden;
}
