.input {
  opacity: 0;
  position: absolute;
  pointer-events: none;

  & + *::before {
    $size: 18px;
    content: "";
    box-sizing: border-box;
    display: inline-block;
    vertical-align: bottom;
    width: $size;
    height: $size;
    border-radius: 2px;
    border-style: solid;
    border-width: 2px;
    border-color: gray;
  }

  &:checked + *::before {
    background-color: var(--color-accent);
    border-color: var(--color-accent);
    background-image: url("../../assets/icons/ic_checkbox_white.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.inputLabel {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: .3rem;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
