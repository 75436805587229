.subTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 32px;

}

.button {
  font-weight: 500;
  width: 10rem;
  height: 2.7rem;
  margin-left: 20px;
}
