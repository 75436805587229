.root {
  display: grid;
  grid-row-gap: 0.5rem;
  padding-left: 2rem;
  z-index: 1;
}

.labelContainer {
  position: relative;
  display: flex;
}

.label {
  font-size: 1rem;
  font-weight: 500;
  margin-right: 3rem;
}

.upload {
  font-size: 1rem;
}

.link {
  display: inline-block;
  cursor: pointer;
  color: inherit;
  align-self: center;
}

.icon {
  position: relative;
  height: 1rem;
  width: 1rem;
  cursor: pointer;
  margin-right: 7px;

  &:hover {
    .bubble {
      visibility: visible;
      opacity: 1;
    }
  }
}

.bubble {
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s;
  font-size: 14px;
}
