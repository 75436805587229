.confirm-dialog {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    flex: 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.17;
    text-align: left;
    padding: 10px;
    color: var(--color-black);
  }

  &__close {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 20px;
    line-height: 1.6;
    padding: 10px;
    color: var(--color-black);

    .sub-text {
      padding-top: 20px;
    }
  }

  &__footer {
    flex: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px;
  }

  &__button {
    min-width: 150px;
    margin-left: 20px;
  }
}
