.container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  margin: 2rem 0 2rem 2rem;
  cursor: pointer;
}

.icon {
  transform: rotate(180deg);
}

.text {
  font-size: 1.2rem;
}
