.sectionHeader {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 1rem;
  margin-bottom: 24px;
  align-items: center;

  &Health {
    margin-bottom: 16px;
  }
}

.sectionTitle {
  font-weight: bold;
  font-size: 1.5rem;
  color: var(--color-black);
  text-decoration: none;
  display: inline-block;
  width: max-content;
}
