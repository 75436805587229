.content {
  display: grid;
  grid-row-gap: 2rem;
}

.header {
  font-size: 2rem;
  font-weight: bold;
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.avatar {
  background-size: cover;
  height: 74px;
  width: 74px;
  margin-right: 1.5rem;
}

.trellusTeamItemName {
  font-size: 1.1rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.4rem;
}

.trellusTeamItemType {
  font-size: 1rem;
  text-align: left;
  color: var(--color-dark-gray);
}

.button {
  width: 10.1rem;
  height: 2.5rem;
  font-size: 0.8rem;
  line-height: 3.3;
  font-weight: bold;
  letter-spacing: 1.16px;
  text-decoration: none;
  background: var(--color-accent);
  color: var(--color-black);
  border-radius: 20px;
  text-transform: uppercase;
  text-align: center;

  &:hover {
    background-color: var(--color-accent-hover);
  }
}

.aboutContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.aboutTitle {
  font-weight: 500;
  font-size: 1rem;
  padding: 2rem;
}

.aboutText {
  font-size: 1rem;
  text-align: left;
  padding: 2rem 0;
  white-space: pre-wrap;
  line-height: 1.5;
}
