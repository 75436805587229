@use 'src/styles/typography' as t;
@use 'src/styles/variables' as v;

.header {
  background-color: var(--color-very-light-grey);

  @media (max-width: v.$screen-responsive-width-low) {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-very-light-grey);
  }
}

.headerContent {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr min-content;
  align-items: center;
  justify-items: start;
}

.authorInfo {
  display: grid;
  grid-template-areas:
    'back avatar title'
    'back avatar type';
  grid-column-gap: 0.5rem;
  grid-row-gap: 0;
}

.back {
  display: none;

  @media (max-width: v.$screen-responsive-width-low) {
    display: block;
    cursor: pointer;
    margin-right: 1rem;
    grid-area: back;
    align-self: center;
  }
}

.authorInfoAvatar {
  grid-area: avatar;
}

.authorInfoTitle {
  grid-area: title;
  color: black;
  font-weight: 500;

  @include t.text-overflow(16px);
}

.authorInfoType {
  grid-area: type;
  color: var(--color-dark-gray);

  @include t.text-overflow(0.8rem);
}

.content {
  display: grid;
  grid-template-rows: 486px min-content;
}

.contentWrapper {
  overflow-y: auto;
}

.contentWrapperNoInput {
  border-bottom: 1px solid var(--color-background);
}

.formContent {
  border-top: 1px solid var(--color-background);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  position: relative;
}

.mockInput {
  width: 100%;
  height: 100%;
  position: absolute;
}

.input {
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  resize: none;
  padding: 1rem 0.75rem;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::placeholder {
    color: var(--color-dark-gray);
  }

  &:focus {
    outline: none;
  }
}

.inputContainer {
  width: 100%;
}

.fileUpload {
  svg {
    display: block !important;
  }

  margin: 12px;
  cursor: pointer;
}

.sendButton {
  margin: 12px;
}

.attachments {
  display: flex;
}

.attachment {
  height: 80px;
  width: 80px;
  margin: 0 8px 10px 0;
  position: relative;
}

.imageAttachment {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.linkAttachment {
  width: 100%;
  height: 76px;
  font-size: 16px;
  padding-top: 2px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--color-link);
  text-decoration: underline;
  cursor: pointer;
}

.attachmentError {
  color: var(--color-danger);
  margin: 5px 12px;
  font-size: 14px;
}

.removeAttachment {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: var(--color-white);
  border-radius: 10px;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    left: 4px;
    top: 10px;
    display: inline-block;
    content: '';
    transform: rotate(45deg);
    height: 1px;
    width: 60%;
    background-color: var(--color-black);
  }

  &:after {
    transform: rotate(130deg);
  }
}
