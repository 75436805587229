.root {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.searchFormTitle {
  text-decoration: none;
  text-align: center;
  color: var(--color-grey-link);
}

.noMyProvider {
  text-decoration: underline;
  color: var(--color-grey-link);
  cursor: pointer;
  text-align: center;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 0 auto;
  font-size: 0.813rem;
  color: var(--color-dark-gray);
}

.noResults {
  font-size: 14px;
  padding: 8px;
  background-color: var( --color-light-pink);
  align-self: center;
  width: fit-content;
}
