.headerSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.button {
  font-size: 13px;
  font-weight: bold;
  width: 12.2rem;
  height: 2.5rem;
  letter-spacing: 1.16px;
  padding: 0;
}
