.header {
  margin-bottom: 0.5rem;
}

.subHeader {
  font-size: 0.8rem;
  letter-spacing: 0.05px;
  color: var(--color-dark-gray);
  margin-bottom: 2rem;
}
