.field {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  font-size: 1rem;


  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-lynx-white);
  }
}

.index {
  padding-left: 2rem;
  padding-right: 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-dark-gray);
}

.label {
  display: flex;
  font-weight: 500;
}

.value {
  height: 100%;

  &.numberType {
    padding: 0 1.5rem;
  }
}
