.root {
  display: flex;
  align-items: flex-start;
  background-color: var(--color-sand);
  padding: 1rem;
  border-radius: 8px;
  font-size: 1rem;
  line-height: 24px;
}

.icon {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.6rem;
  margin-top: 2px;
}
