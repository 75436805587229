.greenLeaf {
  fill: #c8e7de;
}

.lightGreenLeaf {
  fill: #93ccb9;
}

.branch {
  fill: #52695c;
}
