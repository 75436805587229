.body {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 100px;
  padding-top: 40px;
  color: var(--color-secondary);
}

.footer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
