.react-datepicker-popper {
  z-index: 1001 !important;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 0px !important;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 0px !important;
}

.react-datepicker {
  width: 100%;
  border: 1px solid transparent !important;
  box-shadow: 0 24px 53px 0 rgba(0, 0, 0, 0.27);
}

.react-datepicker__month-container,
.react-datepicker__year--container {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.react-datepicker__month-wrapper {
  display: flex;
  justify-content: space-between;
}

.react-datepicker__day-names {
  width: 100%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(7, 1fr);
}

.react-datepicker__week {
  width: 100%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(7, 1fr);
}

.react-datepicker__year-wrapper {
  width: 100%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
}

.react-datepicker .react-datepicker__month {
  margin: 0;
}

.react-datepicker .react-datepicker__header {
  background-color: var(--color-white);
  border-bottom: none;
}

.react-datepicker .react-datepicker__triangle {
  display: none;
}

.react-datepicker .react-datepicker__day-name,
.react-datepicker .react-datepicker__year-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-top: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-dark-gray);
  border: none;
  user-select: none;
  padding: 8px !important;
}

.react-datepicker .react-datepicker__year-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  border: none;
  user-select: none;
  color: var(--color-black);
  margin: 6px;
  padding: 16px 8px !important;
  cursor: pointer;

}

.react-datepicker .react-datepicker__day {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-black);
  user-select: none;
  padding: 8px !important;
  position: relative;
}

.react-datepicker .react-datepicker__aria-live {
  position: absolute;
  clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}

.react-datepicker .react-datepicker__day:hover,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker .react-datepicker__day--selected,
.react-datepicker .react-datepicker__day--selected:hover {
  background-color: var(--color-accent) !important;
  border-radius: 100% !important;
  color: var(--color-black) !important;
  outline: none;
}

.previousDate .customDatePicker .react-datepicker .react-datepicker__day:hover,
.previousDate .customDatePicker .react-datepicker .react-datepicker__day--selected {
  &:after {
    display: none;
  }
}

.customDatePicker .react-datepicker .previousDate {
  background-color: transparent !important;
  color: var(--color-black);
  position: relative;

  &:after {
    content: '';
    border: 2px solid var(--color-accent);
    background-color: transparent !important;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
}

.customDatePicker .react-datepicker .react-datepicker__day--today {
  background-color: transparent !important;
  color: var(--color-black) !important;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:after {
    content: '';
    height: 2px;
    width: 50%;
    background-color: var(--color-accent);
  }
}

.customDatePicker .previousDate.react-datepicker__day--today {
  &:after {
    content: '';
    border: 2px solid var(--color-accent);
    background-color: transparent !important;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
}

.customDatePicker .react-datepicker .react-datepicker__day:hover,
.customDatePicker .react-datepicker .react-datepicker__day--selected,
.customDatePicker .react-datepicker .react-datepicker__year-text:hover,
.customDatePicker .react-datepicker .react-datepicker__year-text--selected {
  background-color: var(--color-accent) !important;
  color: var(--color-white) !important;
  border-radius: 100% !important;
  outline: none;

  &:after {
    display: none;
  }
}

.react-datepicker .react-datepicker__day--disabled {
  color: var(--color-dark-gray);
}

.react-datepicker .react-datepicker__year-text--disabled {
  color: var(--color-dark-gray);
  cursor: default;
}

.react-datepicker .react-datepicker__year-text.react-datepicker__year-text--disabled:hover {
  background-color: inherit !important;
  color: var(--color-dark-gray) !important;
}

.customDatePicker .react-datepicker__day--disabled.react-datepicker__day--today {
  color: var(--color-dark-gray) !important;

  &:after {
    display: none;
  }
}

.react-datepicker .react-datepicker__month-text {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  color: var(--color-black);
  box-sizing: border-box;
  padding-top: 12px;
}


.react-datepicker .react-datepicker__month .react-datepicker__month-text,
.react-datepicker .react-datepicker__month .react-datepicker__quarter-text {
  width: 42px;
  height: 42px;
  margin: 5px;
}

.react-datepicker .react-datepicker__month-text:hover {
  border-radius: 50%;
  background-color: var(--color-accent) !important;
  color: var(--color-white) !important;
  outline: none;
}

.react-datepicker .react-datepicker__month-text--keyboard-selected.react-datepicker__month-text {
  color: var(--color-white) !important;
}

.react-datepicker .react-datepicker__month--selected {
  background-color: var(--color-accent) !important;
  color: var(--color-white) !important;
  outline: none;
}

.react-datepicker .react-datepicker__month--disabled {
  border-radius: 50%;
  color: var(--color-dark-gray) !important;
}

.react-datepicker .react-datepicker__month-text--disabled {
  border-radius: 50%;
  color: var(--color-dark-gray) !important;
  &:hover {
    background-color: inherit !important;
    color: var(--color-dark-gray) !important;
    pointer-events: none;
  }
}

