.modalContent {
  background-color: var(--color-white);
  padding: 40px 30px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 550px;
  min-width: 400px;
  max-height: 80vh;
  border: 2px solid var(--color-primary-yellow);
}

.modalHeader {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalHeader h2 {
  color: var(--color-burgundy);
}

.modalBody {
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .titleHolder {
    display: flex;
    align-items: center;
    gap: 20px;

    .inputTitle {
      flex-grow: 1;
    }
  }

  .questions {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}

.modalFooter {
  z-index: 1;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.radioInput {
  display: flex;
}

.radioInputOptions {
  display: flex;
  gap: 20px;
}

.questionWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  text-align: start;

  .inputField {
    width: 100%;
  }

  .description {
    color: var(--color-secondary);
  }
}
