.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.text {
  font-size: 13px;
  color: var(--color-dark-gray);
  margin-top: 16px;
}
