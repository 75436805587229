.modalRoot {
  width: 31.625rem;
  border-radius: 8px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  padding: 2rem;
}

.modalOut {
  text-decoration: underline;
  color: var(--color-grey-link);
  cursor: pointer;
  text-align: center;
}
