.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem;
  grid-gap: 2em;
  font-size: 1rem;

  &.oneColumn {
    grid-template-columns: 1fr;
  }


  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-lynx-white);
  }
}

.label {
  display: flex;
  font-weight: 500;
  white-space: pre-line;

  &.onlyTitle {
    font-size: 18px;
    font-weight: 700;
  }
}

.value {
  height: 100%;
}

@media (max-width: 979px), (min-width: 1300px) and (max-width: 1679px) {
  .root {
    &.swapColumnsOnMobile {
      grid-template-columns: 1fr;
    }
  }
}
