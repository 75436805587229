.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  font-size: .9rem;
}


.listItemTitle {
  display: flex;
  align-items: center;
}

.danger {
  color: var(--color-danger);
}

.success {
  color: var(--color-success);
}

.create {
  margin-right: auto;
}
