.dropZone {
  background-color: var(--color-gray);
  padding: 36px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  border: 1px dashed var(--color-gainsboro);
  margin-bottom: 10px;
  box-sizing: border-box;
  height: 161px;
}

.dropZoneIcon {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-very-light-grey);
    border-radius: 30px;
    width: 58px;
    height: 58px;

    svg {
      width: 21px;
    }
  }
}

.dropZoneText {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  color: var(--color-dark-gray);

  span {
    color: var(--color-black);
    text-decoration: underline;
    margin: 0 5px;
    cursor: pointer;
  }
}

