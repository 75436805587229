.header {
  margin-bottom: 2rem;
}

.body {
  justify-content: space-between;
  display: flex;
  padding-left: 15px;
  align-items: center;
  height: 100%;
  gap: 15px;
}

.score {
  color: var(--color-danger);
}

.actionHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.spinnerHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.title {
  cursor: pointer;
}

.status {
  margin-top: 4px;
  color: var(--color-achieve);
  font-weight: bold;
}
