.section {
  display: grid;
  grid-row-gap: .5rem;
}

.sectionHeader {
  display: grid;
  grid-column-gap: 1rem;
  padding: 0 0 20px 0;
}

.sectionDescription {
  font-style: italic;
  font-size: 14px;
}

.sectionContent {
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}

.skillForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.skillsContainer {
  display: flex;
  align-items: center;
  flex-flow: wrap;

}

.skill {
  box-sizing: border-box;
  flex: 1 1 50%;
  padding: 10px;
  max-width: 50%;
}

.skillLabel {
  padding: 10px;
}
